<template>
  <div class="modal">
    <div class="modal-content">
      <span @click="close" class="close">&times;</span>
      <div class="modal-header">
        <div class="project-name font-bold">
          {{ projectDetail.name_ja }}
        </div>
        <div class="quantity-display font-semibold">
          購入数量: {{ quantity }}
        </div>
      </div>
      <div class="modal-body">
        <div class="grid grid-cols-1 gap-4">
          <div class="input-group">
            <label for="name">氏名</label>
            <input id="name" type="text" placeholder="氏名" class="input-field" v-model="user.name" maxlength="255" required>
          </div>
          <div class="input-group postal-code-group">
            <label for="postalCode">郵便番号 (ハイフンなし)</label>
            <div class="postal-code-container">
              <input id="postalCode" type="tel" placeholder="郵便番号" class="input-field postal-code" v-model="user.postalCode" maxlength="7" required>
            </div>
          </div>
          <div class="input-group address-group">
            <label for="address">住所</label>
            <input id="address" type="text" placeholder="住所" class="input-field address-input" v-model="user.address" maxlength="255" required>
          </div>
          <div class="input-group">
            <label for="phoneNumber">電話番号 (ハイフンなし)</label>
            <input id="phoneNumber" type="tel" placeholder="電話番号" class="input-field" v-model="user.phoneNumber" maxlength="11" required>
          </div>
          <div class="input-group">
            <label for="email">メールアドレス</label>
            <input id="email" type="email" placeholder="メールアドレス" class="input-field" v-model="user.email" maxlength="255" required>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button @click="onAddressSubmitForm" class="submit-button">
          フォームを送信し暗号資産で購入
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { addShippingInfo } from '@/services/shipping_info';

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    quantity: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      user: {
        name: '',
        phoneNumber: '',
        email: '',
        postalCode: '',
        address: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      account: 'wallet/account',
    }),
    ...mapState({
      provider: (state) => state.wallet?.provider || null,
      projectDetailMap: (state) => state.projects.projectDetailMap,
      locale: (state) => state?.setting.locale,
    }),
    projectDetail() {
      const { id } = this.$route.params;
      if (id) {
        return this.projectDetailMap[id] || {};
      }
      return {};
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    validateInput() {
      if (this.user.name.length > 255 || !this.user.name) {
        //255文字を超えて入力できないため、通常未入力の場合のみこのルートを通る
        alert('氏名が未入力です。');
        return false;
      }
      if (this.user.postalCode.length > 7 || !this.user.postalCode) {
        //7文字を超えて入力できないため、通常未入力の場合のみこのルートを通る
        alert('郵便番号が未入力です。');
        return false;
      }
      if (this.user.address.length > 255 || !this.user.address) {
        //255文字を超えて入力できないため、通常未入力の場合のみこのルートを通る
        alert('住所が未入力です。');
        return false;
      }
      if (this.user.phoneNumber.length > 11 || !this.user.phoneNumber) {
        //11文字を超えて入力できないため、通常未入力の場合のみこのルートを通る
        alert('電話番号が未入力です。');
        return false;
      }

      if (this.user.email.length > 255 || !this.user.email) {
        //256文字以上は入力できないため、通常未入力の場合のみこのルートを通る
        alert('メールアドレスが未入力です。');
        return false;
      }
      return true;
    },
    validateEmail() {
      //以下条件で検証
      //1. @ の前には、空白文字や @ 自体を含まない1文字以上の文字列が含まれる
      //2. @ の直後にも、同様に空白文字や @ を含まない1文字以上の文字列が含まれる
      //3. 最後に、ピリオド . が続き、その後に空白文字や @ を含まない1文字以上の文字列が含まれる
      const regex = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;
      return regex.test(this.user.email);
    },
    validatePhoneNumber() {
      //以下条件で検証
      //1. 数字のみ
      //2. 10~11文字であること
      //3. 先頭文字が0であること
      const regex = /^0\d{9,10}$/;
      return regex.test(this.user.phoneNumber);
    },
    validatePostalCode() {
      //以下条件で検証
      //1. 数字のみ
      //2. 7文字であること
      const regex = /^[0-9]{7}$/;
      return regex.test(this.user.postalCode);
    },
    async onAddressSubmitForm(event) {
      if (!this.validateInput()) {
        event.preventDefault();
        return;
      }
      if(!this.validatePostalCode()){
        alert('郵便番号は7桁の数字でご入力ください。');
        event.preventDefault();
        return;
      }
      if(!this.validatePhoneNumber()){
        alert('電話番号は0から始まる10桁または11桁の数字でご入力ください。');
        event.preventDefault();
        return;
      }
      if (!this.validateEmail()){
        alert('メールアドレスが不正な形式です。');
        event.preventDefault();
        return;
      }

      try {
        if(!this.account){
          alert('ウォレットアドレスが取得できませんでした。');
          throw 'wallet is not obtained';
        }
        const payload = new FormData();
        payload.append('backend_id', this.$route.params.id);
        payload.append('project_name', this.projectDetail.name);
        payload.append('name', this.user.name);
        payload.append('amount', this.quantity);
        payload.append('wallet', this.account);
        payload.append('phone_number', this.user.phoneNumber);
        payload.append('mail', this.user.email);
        payload.append('zipcode', this.user.postalCode);
        payload.append('address', this.user.address);

        await addShippingInfo(payload);
        this.$emit('close');
        this.$emit('submitBuy');
      } catch (err) {
        console.error(err);
        this.$emit('close');
        event.preventDefault();
      }
    },
    async lookupAddress() {
      if (!this.user.postalCode || this.user.postalCode.length < 7) {
        alert('郵便番号は7桁を入力してください。');
        return;
      }

      const apiUrl = `https://api.zipaddress.net/?zipcode=${this.user.postalCode}`;

      try {
        const response = await fetch(apiUrl);
        const data = await response.json();
        if (data.code === 200) {
          this.user.address = data.data.fullAddress;
        } else {
          alert('住所が見つかりませんでした。');
        }
      } catch (error) {
        console.error('住所検索中にエラーが発生しました:', error);
        alert('住所の検索に失敗しました。');
      }
    },
  },
};
</script>

<style scoped>
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1050; 
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  font-family: 'Meiryo', sans-serif;
  overflow-y: auto; /* Y軸にスクロールバーを設定 */
  transition: transform 0.3s ease-in-out;
}

.modal-content {
  display: flex;
  flex-direction: column;
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 100%; 
  max-width: 600px; 
  max-height: 100vh; 
  border-radius: 8px;
}

.close {
  color: #aaa;
  align-self: flex-end;
  font-size: 24px; 
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  margin-bottom: 0.8rem;
  background-color: #C24ADC;
  width: 100%;
  padding: 12px;
  border-radius: 8px 8px 0 0;
  font-family: 'Meiryo', sans-serif;
}

.modal-body {
  flex: 1;
  overflow-y: auto;
  padding-right: 1rem;
  position: relative;
  font-family: 'Meiryo', sans-serif;
}

.modal-footer {
  display: flex;
  justify-content: center;
  padding: 1rem 0; 
  bottom: 0;
  border-top: 1px solid #ccc;
}

.input-group {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 0.7rem;
  width: 100%;
  font-family: 'Meiryo', sans-serif;
}

.input-group label {
  margin-bottom: 0.5rem;
  color: rgba(68, 37, 125, 1); 
  font-family: 'Meiryo', sans-serif;
}

.input-field {
  flex: 1;
  border: 1px solid #ccc;
  padding: 0.5rem;
  border-radius: 4px;
  color: black;
  width: 100%;
  font-family: 'Meiryo', sans-serif;
}

.postal-code-group {
  width: 100%;
}

.postal-code-container {
  display: flex;
  width: 100%;
  font-family: 'Meiryo', sans-serif;
}

.postal-code {
  flex: none;
  width: 120px;
  margin-right: 0.5rem;
  font-family: 'Meiryo', sans-serif;
}

.search-button {
  flex: none;
  padding: 0.8rem 1rem;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Meiryo', sans-serif;
}

.search-button:hover {
  background-color: #0056b3;
}

.address-group {
  width: 100%;
}

.address-input {
  width: 100%;
  font-family: 'Meiryo', sans-serif; 
}

.submit-button {
  padding: 12px 20px; 
  background-color: #C24ADC;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: block; 
  margin-top: 10px; 
  font-family: 'Meiryo', sans-serif; 
}

.submit-button:hover {
  background-color: #45a049;
}

.quantity-display {
  font-size: 1rem; 
  display: block; 
  color: white; 
  font-family: 'Meiryo', sans-serif;
  @media (max-width: 768px) {
    font-size: 0.6rem;
  }
}

.project-name {
  @media (max-width: 768px) {
    font-size: 0.6rem;
  }

  font-size: 1rem;
  display: block;
  color: white;
  font-family: 'Meiryo', sans-serif;
}

.modal-body::after {
  content: '';
  display: block;
  width: 100%;
  height: 20px;
  background: linear-gradient(to top, #fefefe, transparent);
  position: absolute;
  bottom: 0;
  left: 0;
}

.modal-footer {
  bottom: 0;
  background: #fefefe;
  border-top: 1px solid #ccc;
}

@media (max-width: 768px) {
    .modal-content {
      max-width: 95%;
      max-height: 85vh;
      padding: 10px;
    }

    .modal {
      padding: 10px; 
    }

    .input-field, .postal-code-container {
        width: auto; 
    }

    .submit-button {
        width: 100%; 
        margin-top: 10px; 
    }
    .search-button {
        width: 100px; 
        margin-top: 10px; 
    }

    .input-group label,
    .modal-footer{
      font-size: 10px;
    }
    @media (max-width: 400px) {
    .modal-content {
      max-width: 85%;
      max-height: 70vh;
    }
    .search-button {
        width: 60px; 
    }
    .input-field.postal-code {
        width: 150px; 
    }
  }
}
</style>
