<!--
  SMARTPHONE
    LAYOUT
      height: 46px; 2行、PCは height: 21px; 1行
    TYPOGRAPHY PCも同じ
      font-family: Inter;
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
    COLOR PCも同じ
      background: #9B9DA1;
-->
<template> 
  <div>
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- パンくずリスト -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <a-breadcrumb
      separator=""
      class="w-full grow"
      style="font-family: Inter; font-size: 14px; font-weight: 700;
            color: #9B9DA1;"
    > <!-- セパレータ ＞ の色はここで指定する -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- 第一ディレクトリ。ルートディレクトリ。-->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <a-breadcrumb-item class="cursor-pointer">
        <span @click="() => $router.push('/')">
          {{ $t('HOME_BREAD_CRUMB_TEXT') }}
        </span>
      </a-breadcrumb-item>
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- セパレータ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <a-breadcrumb-item-separator v-if="arrayHierarchy[0]">
        ＞
      </a-breadcrumb-item-separator>
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- 第二ディレクトリ。通常はカテゴリ。-->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <a-breadcrumb-item
        v-if="arrayHierarchy[0]" class="cursor-pointer"
      >
        <span @click="moveCategoryPath();">
          {{ this.showCategoryName() }}
        </span>
      </a-breadcrumb-item>
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- セパレータ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <a-breadcrumb-item-separator v-if="arrayHierarchy[1]">
        ＞
      </a-breadcrumb-item-separator>
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- 第三ディレクトリ。通常はプロジェクト（漫画家、アーティスト）。-->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <a-breadcrumb-item
        v-if="arrayHierarchy[1]" class="cursor-pointer"
      >
        <span @click="move3rdDirectoryPath();">
          {{ this.showProjectName() }}
        </span>
      </a-breadcrumb-item>
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- セパレータ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <a-breadcrumb-item-separator v-if="arrayHierarchy[2]">
        ＞
      </a-breadcrumb-item-separator>
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- 第四ディレクトリ。通常はシリーズ。-->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <a-breadcrumb-item
        v-if="arrayHierarchy[2]" class="cursor-pointer"
      >
        <span @click="move4thDirectoryPath();">
          {{ this.showSiriesName() }}
        </span>
      </a-breadcrumb-item>
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- セパレータ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <a-breadcrumb-item-separator v-if="arrayHierarchy[3]">
        ＞
      </a-breadcrumb-item-separator>
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- 第五ディレクトリ。通常はエディション。-->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <a-breadcrumb-item
        v-if="arrayHierarchy[3]" class="cursor-pointer"
      >
        <span @click="move5thDirectoryPath();">
          {{ this.showEditionName() }}
        </span>
      </a-breadcrumb-item>
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- セパレータ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <a-breadcrumb-item-separator>
        ＞
      </a-breadcrumb-item-separator>
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- 当該ページ情報。クリックによる遷移はしない。-->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <a-breadcrumb-item>
        <span style="color: #9B9DA1;">
          {{ this.showNFTName() }}
        </span>
      </a-breadcrumb-item>
    </a-breadcrumb>
  </div>
</template>
<!-- ====+=========+=========+=========+=========+=========+=========+====== -->
<script>
import { getCategories } from '@/services/categories';
import { mapState } from 'vuex';
//-------+---------+---------+---------+---------+---------+---------+---------E
export default {
  name: 'BreadcrumbList',
  //-----+---------+---------+---------+---------+---------+---------+---------E
  data() {
    return {
      arrayHierarchy: [], // hierarchy を'\\'で分割。
      category_id: 0,
      category_ja: '',
      category_en: '',
      project_id: 0,
      project_ja: '',
      project_en: '',
      siries_id: 0,
      siries_ja: '',
      siries_en: '',
      edition_id: 0,
      edition_ja: '',
      edition_en: '',
    }; // End of return {...}
  }, // End of data() {...}
  //-----+---------+---------+---------+---------+---------+---------+---------E
  mounted() {
    this.getNames()
  },
  //-----+---------+---------+---------+---------+---------+---------+---------E
  watch: {
    hierarchy(newValue) {
      this.hierarchy = newValue;
      this.getNames()
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    name_en(newValue) {
      this.name_en = newValue;
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    name_ja(newValue) {
      this.name_ja = newValue;
    },
  }, // End of watch: {...}
  //-----+---------+---------+---------+---------+---------+---------+---------E
  computed: {
    ...mapState({
      locale   : (state) => state?.setting.locale,
      hierarchy: (state) => state.hierarchy.hierarchy,
      name_en  : (state) => state.hierarchy.name_en,
      name_ja  : (state) => state.hierarchy.name_ja,
    }), // End of ...mapState({...})
  }, // End of computed: {...}
  //-----+---------+---------+---------+---------+---------+---------+---------E
  methods: {
    // hierarchy から、カテゴリ名、プロジェクト（漫画家、アーティスト）名、
    // シリーズ名、エディション名、を取得（日本語・英語とも）。
    async getNames() {
      //alert(`this.hierarchy=${this.hierarchy}: getNames(): BredcrumbList.vue`);
      // +---------+---------+---------+---------+---------+---------+---------E
      // hierarchy が空の場合。
      if (this.hierarchy == '') {
        this.arrayHierarchy[0] = undefined; // category
        this.arrayHierarchy[1] = undefined; // creator(project)
        this.arrayHierarchy[2] = undefined; // series
        this.arrayHierarchy[3] = undefined; // edition
        //alert(`this.arrayHierarchy=${JSON.stringify(this.arrayHierarchy)}: getNames(): BredcrumbList.vue`);
        return; // 戻り値は使わない
      } // End of if (this.hierarchy == '') {...}
      // +---------+---------+---------+---------+---------+---------+---------E
      // hierarchy に値が有る場合。
      this.arrayHierarchy = this.hierarchy.split('\\');
      //alert(`this.arrahHierarchy=${JSON.stringify(this.arrayHierarchy)}: getNames(): BredcrumbList.vue`);
      // カテゴリid、カテゴリ名（日本語、英語）を取得。
      let {items: items} = await getCategories(
        {
          type: 0, // カテゴリ（comic, art, NFTCollection, Game etc.）
          name_en: this.arrayHierarchy[0],
        }
      ); // End of const {items: items} = await getCategories(...)
      // alert(`items: ${JSON.stringify(items)}`);
      this.category_id = items[0].id;
      this.category_ja = items[0].name_ja;
      this.category_en = items[0].name_en;
      //alert(`this.category_id=${this.category_id}, ` +
      //      `this.category_ja=${this.category_ja}, ` +
      //      `this.category_en=${this.category_en}: getNames(): BredcrumbList.vue`);
      // +---------+---------+---------+---------+---------+---------+---------E
      if (this.arrayHierarchy[1] != undefined) {
        // プロジェクト（漫画家、アーティスト）id, 日本語名、英語名を取得。
        let {items: items} = await getCategories(
          {
            type: 1, // クリエイター、プロジェクト（漫画家、アーティスト）
            hierarchy: this.arrayHierarchy[0],
            name_en: this.arrayHierarchy[1],
          }
        ); // End of const {items: items} = await getCategories(...)
        this.project_id = items[0].id;
        this.project_ja = items[0].name_ja;
        this.project_en = items[0].name_en;
        // --------+---------+---------+---------+---------+---------+---------E
        if (this.arrayHierarchy[2] != undefined) {
          // シリーズid, 日本語名、英語名を取得。
          let {items: items} = await getCategories(
            {
              type: 2, // シリーズ
              hierarchy: `${this.arrayHierarchy[0]}\\${this.arrayHierarchy[1]}`,
              name_en: this.arrayHierarchy[2],
            }
          ); // End of  const {items: items} = await getCategories(...)
          this.siries_id = items[0].id;
          this.siries_ja = items[0].name_ja;
          this.siries_en = items[0].name_en;
          // ------+---------+---------+---------+---------+---------+---------E
          if (this.arrayHierarchy[3] != undefined) {
            // エディションid, 日本語名、英語名を取得。
            let {items: items} = await getCategories(
              {
                type: 3, // エディション
                hierarchy: `${this.arrayHierarchy[0]}\\${this.arrayHierarchy[1]}\\${this.arrayHierarchy[2]}`,
                name_en: this.arrayHierarchy[3],
              }
            ); // End of const {items: items} = await getCategories(...)
            this.edition_id = items[0].id;
            this.edition_ja = items[0].name_ja;
            this.edition_en = items[0].name_en;
          } // End of if (this.arrayHierarchy[3] != undefined) {...}
        } // End of if (this.arrayHierarchy[2] != undefined) {...}
      } // End of if (this.arrayHierarchy[1] != undefined) {...}
    }, // End of async getNames() {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    showCategoryName() {
      return this.locale === 'ja' ? this.category_ja : this.category_en;
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    showProjectName() {
      return this.locale === 'ja' ? this.project_ja : this.project_en;
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    showSiriesName() {
      return this.locale === 'ja' ? this.siries_ja : this.siries_en;
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    showEditionName() {
      return this.locale === 'ja' ? this.edition_ja : this.edition_en;
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    showNFTName() {
      return this.locale === 'ja' ? this.name_ja : this.name_en;
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    // hierarchy から二番目（通常、カテゴリ）のパスを算出。
    // ちなみに、一番目はルートディレクトリ。
    async moveCategoryPath() {
      const result = '/Category/' + this.category_id;
      this.$router.push(result);
    }, // End of async moveCategoryPath() {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    // hierarchy から左から
    // 三番目（通常、プロジェクト（漫画家、アーティスト））のパスを算出。
    async move3rdDirectoryPath() {
      const result = '/Creator/' + this.project_id;
      this.$router.push(result);
    }, // End of async move3rdDirectoryPath() {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    // hierarchy と name_en から
    // 左から四番目（通常、シリーズ）のパスを算出。
    async move4thDirectoryPath() {
      const result = '/Series/' + this.siries_id;
      this.$router.push(result);
    }, // End of async move4thDirectoryPath() { {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    // hierarchy と name_en から
    // 左から五番目（通常、エディション）のパスを算出。
    async move5thDirectoryPath() {
      const result = '/Edition/' + this.edition_id;
      this.$router.push(result);
    }, // End of async move5thDirectoryPath() {...}
  }, // End of methods: {...}
}; // End of export default {...}
</script>
<!-- ====+=========+=========+=========+=========+=========+=========+====== -->
<style lang="scss"></style>
