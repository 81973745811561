<template>
  <a-spin :spinning="loading">
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- ↓ パンくずリスト ↓ -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!--
      https://www.figma.com/design/h06kyWSS89LJLZPoVOe4nO
        /Zaif-INO-UI%2FUX%E3%82%A2%E3%83%83%E3%83%97%E3%83%87%E3%83%BC%E3%83%88
        ?node-id=0-1&t=m1IYObJw7jCfaewl-0
      パンくずリスト、スマホ
        LAYOUT
          height: 46px; 2行
          top: 82px;  13px(82-(69+0)) mt-3.5 margin-top: 0.875rem; /* 14px */ 小さすぎ mt-6 にした
          left: 17px;  mx-4 margin-left: 1rem; /* 16px */ margin-right: 1rem; /* 16px */スマホ mx-4採用
        TYPOGRAPHY
          font-family: Inter;
          font-size: 14px;
          font-weight: 700;
      line-height: 21px;
          text-align: left;
        BORDER
          background: #9B9DA1;
    -->
    <!-- 画面幅 640px を境にインデント値を変更 -->
    <div class="mt-6 mx-4 sm:ml-32"
      style="font-family: Inter; font-size: 14px; font-weight: 700;
            color: #9B9DA1;"
    >
      <BreadcrumbList></BreadcrumbList>
    </div>
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- ↑ パンくずリスト ↑ -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- +=========+=========+=========+=========+=========+=========+====== -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- ↓ 商品名とシェアアイコン。画面幅 640px 未満の時のみ表示。↓ -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <div class="flex items-center justify-between mt-6 mx-4 visible sm:hidden">
      <!-- +---------+---------+---------+---------+---------+---------+------ -->
      <!-- ↓ 商品名 ↓ -->
      <!-- +---------+---------+---------+---------+---------+---------+------ -->
      <!--
        商品名
          LAYTOUT
            height: 21px;
            top: 152px;  24px(152-(82+46))  mt-6 margin-top: 1.5rem; /* 24px */採用↑
            left: 16px;  mx-4 margin-left: 1rem; /* 16px */ margin-right: 1rem; /* 16px */ 採用
          TYPOGRAPHY
            font-family: Inter;
            font-size: 24px;
            font-weight: 700;
            line-height: 21px;
          COLOR
            background: #1F2023;
      -->
      <div class="title"
          style="font-family: Inter; font-size: 24px; font-weight: 700;
          color: #1F2023;"    
      >
        {{ showName }}
      </div>
      <!-- +---------+---------+---------+---------+---------+---------+------ -->
      <!-- ↑ 商品名 ↑ -->
      <!-- +---------+---------+---------+---------+---------+---------+------ -->
    </div>
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- ↑ 商品名とシェアアイコン。画面幅 640px 未満の時のみ表示。↑ -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- +=========+=========+=========+=========+=========+=========+====== -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- ↓ 画面幅 640px 以上の場合、2:3 の二列にしている。 ↓ -->
    <!-- ↓ 画面幅 640px 未満の場合、一列。                 ↓ -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <div class="sm:grid grid-cols-5 gap-x-10">
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ↓ 画面幅 640px 以上の場合、左列（2:3の2）。                  ↓ -->
      <!-- ↓ 画面幅 640px 未満の場合、画面横一杯。                      ↓ -->
      <!-- ↓ サムネイル画像、Websiteアイコンとエックスアイコン、        ↓ -->
      <!-- ↓ 画面幅 640px 以上で、チェーン・規格・コントラクトアドレス。↓ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <div class="col-span-5 sm:col-span-2 sm:ml-32">
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↓ サムネイル画像 ↓ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!--
          サムネイル画像
            SmartPhone
              width: 358px;
              height: 358px;
              top: 197px; 24px(197-(152+21)) mt-6 margin-top: 1.5rem; /* 24px */採用
            PC
              width: 400px;
              height: 400px;
              top: 152px; 33px(152-(98+21))  mt-8 margin-top: 2rem; /* 32px */ mt-6にした
        -->
        <div class="z-30 relative flex justify-center">
          <img
            class="mt-6 px-4 sm:px-0"
            :src="detail.thumbnail_url"
          />
        </div>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↑ サムネイル画像 ↑ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ======+=========+=========+=========+=========+=========+====== -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↓ Websiteアイコンとエックスアイコンの組をセンタリング ↓ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <div class="flex mx-auto mt-3.5">
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↓ Websiteアイコンとエックスアイコンの組 ↓ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <div class="flex mx-auto items-end">
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↓ Websiteアイコン ↓ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!--
              スマホ PC 共通
                width: 35px; w-9 width: 2.25rem; /* 36px */
                height: 35px; h-9 height: 2.25rem; /* 36px */
                top: 568px;  13px(568-(358+197))  mt-3.5 margin-top: 0.875rem; /* 14px */
                background: #FF0085;
              スマホ PC で異なる
                left: 153px; スマホ
                left: 328px; PC
            -->
            <a
              v-if="detail.website_url"
              :href="
                detail.website_url.startsWith('http') || detail.website_url.startsWith('www.')
                  ? detail.website_url
                  : `//${detail.website_url}`
              "
              target="_blank"
            >
              <GlobalIcon class="w-9 h-9" style="color: #FF0085;"></GlobalIcon>
            </a>
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↑ Websiteアイコン ↑ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↓ エックスアイコン ↓ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!--
              スマホ PC 共通
                width: 30px; w-8	width: 2rem; /* 32px */採用
                height: 31px; h-8	height: 2rem; /* 32px */ mt-9 にした
              スマホ PC 異なる
                left: 210px;  22px(210-(153+35)) ml-6 margin-left: 1.5rem;スマホ /* 24px */採用
                left: 385px;  22px(385-(328+35)) ml-6 margin-left: 1.5rem;PC /* 24px */
            -->
            <a
              v-if="detail.twitter_url"
              :href="
                detail.twitter_url.startsWith('http') || detail.twitter_url.startsWith('www.')
                  ? detail.twitter_url
                  : `//${detail.twitter_url}`
              "
              target="_blank"
            >
              <XIcon class="w-8 h-9 -mt-1 ml-6" style="color: #FF0085;"></XIcon>
            </a>
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↑ エックスアイコン ↑ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
          </div>
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↑ Websiteアイコンとエックスアイコンの組 ↑ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
        </div>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↑ Websiteアイコンとエックスアイコンの組をセンタリング ↑ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ======+=========+=========+=========+=========+=========+====== -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↓ チェーン・規格・コントラクトアドレス。↓ -->
        <!-- ↓ 画像幅 640px 以上時のみ表示。         ↓ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!--
          チェーン・規格・コントラクトアドレス。PCのみ。
            LAYOUT
              width: 399px;
              height: 108px;
              top: 619px;  22px(619-(562+35))  mt-5 margin-top: 1.25rem; /* 20px */採用↓
              border-radius: 8px 0px 0px 0px;
              border: 1px 0px 0px 0px;
            COLOR
              background: #1F2023; 多分文字色
            BORDER
              border: 1px solid #EBEBEC
        -->
        <div class="hidden sm:block mt-5 pl-4 pr-2 pb-3.5 mx-auto text-sm"
            style="font-family: Noto Sans CJK JP; font-size: 14px;
            color: #1F2023; border: 1px solid #EBEBEC;"
        >
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↓ チェーン PC ↓  -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!--
            LAYOUT
              height: 21px;
              top: 632px;  13px(632-619) mt-3.5	margin-top: 0.875rem; /* 14px */採用
            TYOPGRAPHY
              font-family: Noto Sans CJK JP;
              font-size: 14px;
              font-weight: 700; / font-size: 14px;
              line-height: 21px;
            COLOR
              background: #1F2023;
          -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <div class="flex mt-3.5 flex-wrap">
            <div style="font-weight: 700;">
              {{ $t('PROJECT_CHAIN_LABEL') }}
            </div>&emsp;
            <div style="font-weight: 400; word-break: break-all;">
              {{ getChainName(detail.chain_id) }}
            </div>
          </div>
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↑ チェーン PC ↑  -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↓ 規格 PC ↓  -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!--
            規格
              LAYOUT
                height: 21px;
                top: 660px; 7px(660-(632+21))  mt-2 margin-top: 0.5rem; /* 8px */採用↓
              TYPOGRAPHY
                font-family: Noto Sans CJK JP;
                font-size: 14px;
                font-weight: 700; / font-weight: 400;
                line-height: 21px;
              COLOR
                background: #1F2023;
          -->
          <div class="flex mt-2">
            <div style="font-weight: 700;">
              {{ $t('STANDARD') }}
            </div> &emsp; 
            <div style="font-weight: 400;">
              ERC-{{ detail.contract_type }}
            </div>
          </div>
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↑ 規格 PC ↑  -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↓ コントラクトアドレス PC ↓  -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!--
            コントラクトアドレス
              LAYOUT
                height: 21px;
                top: 689px;  8px(689-(660+21))  mt-2 margin-top: 0.5rem; /* 8px */採用↓
              TYPOGRAPHY
                font-family: Noto Sans CJK JP;
                font-size: 14px;
                font-weight: 700; / font-weight: 400;
                line-height: 21px;
              COLOR
                background: #1F2023;
          -->
          <div v-if="shortenContractAddress()" class="mt-2">
            <div class="font-bold">
              {{ $t('CONTRACT_ADDRESS') }}
            </div>
            <div class="flex flex-col tablet:flex-col lg:flex-row items-start lg:items-center lg:space-y-0 lg:space-x-4 relative">
              <a 
                :href="scanUrl" 
                target="_blank" 
                rel="noopener noreferrer"
                class="font-normal rounded px-3 py-1 lg:px-1 lg:py-1 text-blue-500 hover:underline"
                style="max-width: 100%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
              >
                {{ shortenContractAddress() }}
              </a>
            </div>
          </div>
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↑ コントラクトアドレス PC ↑  -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
        </div>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↑ チェーン・規格・コントラクトアドレス。↑ -->
        <!-- ↑ 画像幅 640px 以上時のみ表示。         ↑ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
      </div>
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ↑ 画面幅 640px 以上の場合、左列（2:3の2）。                  ↑ -->
      <!-- ↑ 画面幅 640px 未満の場合、画面横一杯。                      ↑ -->
      <!-- ↑ サムネイル画像、Websiteアイコンとエックスアイコン、        ↑ -->
      <!-- ↑ 画面幅 640px 以上で、チェーン・規格・コントラクトアドレス。↑ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ========+=========+=========+=========+=========+=========+====== -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ↓ 画面幅 640px 以上の場合、右列（2:3の3）。↓ -->
      <!-- ↓ 画面幅 640px 未満の場合、画面幅一杯。    ↓ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <div class="mt-6 mx-2 sm:ml-0 sm:mr-16 col-span-5 sm:col-span-3">
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↓ 商品名とシェアアイコンと「シェア」文字列。↓ -->
        <!-- ↓ 横幅 640px 以上の画面のみ表示。           ↓ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <div class="hidden sm:block">
          <div class="flex items-center justify-between mt-2">
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↓ 商品名 ↓ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!--
              商品名、ＰＣのみ
                LAYOUT
                  height: 21px;
                  top: 152px;  33px(152-(98+21)) mt-8 margin-top: 2rem; /* 32px */ mt-6 + mt-2 ↑
                TYPOGRAPHY
                  font-family: Inter;
                  font-size: 24px;
                  font-weight: 700;
                  line-height: 21px;
                COLOR
                  background: #1F2023;
            -->
            <div class="title"
                style="font-family: Inter; font-size: 24px; font-weight: 700;
                color: #1F2023;"    
            >
              {{ showName }}
            </div>
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↑ 商品名 ↑ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
          </div>
        </div>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↑ 商品名とシェアアイコンと「シェア」文字列。↑ -->
        <!-- ↑ 横幅 640px 以上の画面のみ表示。           ↑ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ======+=========+=========+=========+=========+=========+====== -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↓ 購入フォーム。データが有るときのみ表示。↓ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <div
          v-if="Object.values(detail).length > 0"
          class="pb-8 mx-auto rounded-t-xl"
        >
          <div class="relative">
            <div v-if="isMaintaining">
              <div>ご案内</div>
              2023年7月7日(金)19:00から、Zaif
              INOのサービスサイトにて、購入ができないエラーが発生しております。
              <br />
              多くのお客さまに
              <br />
              ご迷惑をおかけ致しまして誠に申し訳ございませんでした。
              <br />
              現在システムチームとエラーの原因を調査中です。
              <br />
              無事復旧いたしましたら、改めてお知らせいたしますので、今しばらくお待ちいただけましたら幸いです。
              <br />
              何卒宜しくお願い致します。
            </div>
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↓ プレセール、販売量 > 0 時のみ、価格、販売総数、↓ -->
            <!-- ↓ 残り、開始終了日時、注文数、購入ボタン         ↓ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <BuySection
              v-if="detail.presale_amount > 0"
              type="PRESALE"
              :startTime="detail.presale_start_time"
              :startTimeInMilliseconds="presaleStartTime"
              :endTime="detail.presale_end_time"
              :endTimeInMilliseconds="presaleEndTime"
              :chainId="detail.chain_id"
              :price="detail.presale_price"
              :totalQuantity="detail.presale_amount"
              :sold="detail.presale_sold_amount"
              :maxSalePerUser="detail.presale_max_purchase"
              :contractId="detail.contract_project_id"
              @buying="onBuying"
              :isWhitelisted="canBuy"
              :calendarUrl="calendarUrl(false)"
              :stopped="detail.status === 4"
              :isReady="detail.status_code === 'READY'"
              :isTBA="detail.is_presale_tba"
              :isPriceTBA="detail.is_presale_price_tba"
              :target="presaleTarget"
              :transferUnsoldDisabled="detail.transfer_unsold_disabled"
              :tokenAddress="detail.token_address"
              :allowance="currentAllowance"
              @onApproveToken="approve"
              :hasAffiliate="detail.is_affiliate_enabled"
              :isMaintaining="isMaintaining"
            ></BuySection>
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↑ プレセール、販売量 > 0 時のみ、価格、販売総数、↑ -->
            <!-- ↑ 残り、開始終了日時、注文数、購入ボタン         ↑ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ==+=========+=========+=========+=========+=========+====== -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↓ パブリックセール、販売量 > 0 時のみ、価格、販売総数、↓ -->
            <!-- ↓ 残り、開始終了日時、注文数、購入ボタン               ↓ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <BuySection
              v-if="detail.publicsale_amount > 0"
              type="PUBLIC_SALE"
              :startTime="detail.publicsale_start_time"
              :startTimeInMilliseconds="publicSaleStartTime"
              :endTime="detail.publicsale_end_time"
              :endTimeInMilliseconds="publicSaleEndTime"
              :chainId="detail.chain_id"
              :price="detail.publicsale_price"
              :totalQuantity="publicSaleTotalAmount"
              :sold="detail.publicsale_sold_amount"
              :maxSalePerUser="detail.publicsale_max_purchase"
              :contractId="detail.contract_project_id"
              @buying="onBuying"
              :calendarUrl="calendarUrl(true)"
              :stopped="detail.status === 4"
              :isReady="detail.status_code === 'READY'"
              :isTBA="detail.is_publicsale_tba"
              :isPriceTBA="detail.is_publicsale_price_tba"
              :target="publicSaleTarget"
              :transferUnsoldDisabled="detail.transfer_unsold_disabled"
              :tokenAddress="detail.token_address"
              :allowance="currentAllowance"
              @onApproveToken="approve"
              :hasAffiliate="detail.is_affiliate_enabled"
              :isMaintaining="isMaintaining"
            ></BuySection>
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↑ パブリックセール、販売量 > 0 時のみ、価格、販売総数、↑ -->
            <!-- ↑ 残り、開始終了日時、注文数、購入ボタン               ↑ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
          </div>
          <!-- ====+=========+=========+=========+=========+=========+====== -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↓ 商品説明 ↓ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!--
            スマホ
              width: 351px;
              height: 924px;
              top: 1156px;
              left: 25px;
              font-family: Inter;
              font-size: 16px;
              font-weight: 700;
             line-height: 21px;
              background: #1F2023;
            PC
              width: 700px;
              height: 861px;
              top: 501px;
              left: 588px;
              font-family: Inter;
              font-size: 16px;
              font-weight: 700;
              line-height: 21px;
              background: #1F2023;
          -->
          <section class="header md:flex space-x-8 align-middle
                          mt-8 mb-4 mx-2 sm:mx-0"
          >
            <div class="general w-full grow">
              <div class="description text-lg whitespace-pre-line text-black">
                <div v-html="$sanitize(showDescription)" />
              </div>
            </div>
          </section>
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↑ 商品説明 ↑ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ====+=========+=========+=========+=========+=========+====== -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↓ プレセール、販売量 > 0 時のみ、価格、販売総数、↓ -->
          <!-- ↓ 残り、開始終了日時、注文数、購入ボタン         ↓ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <BuySection
            v-if="detail.presale_amount > 0"
            type="PRESALE"
            :startTime="detail.presale_start_time"
            :startTimeInMilliseconds="presaleStartTime"
            :endTime="detail.presale_end_time"
            :endTimeInMilliseconds="presaleEndTime"
            :chainId="detail.chain_id"
            :price="detail.presale_price"
            :totalQuantity="detail.presale_amount"
            :sold="detail.presale_sold_amount"
            :maxSalePerUser="detail.presale_max_purchase"
            :contractId="detail.contract_project_id"
            @buying="onBuying"
            :isWhitelisted="canBuy"
            :calendarUrl="calendarUrl(false)"
            :stopped="detail.status === 4"
            :isReady="detail.status_code === 'READY'"
            :isTBA="detail.is_presale_tba"
            :isPriceTBA="detail.is_presale_price_tba"
            :target="presaleTarget"
            :transferUnsoldDisabled="detail.transfer_unsold_disabled"
            :tokenAddress="detail.token_address"
            :allowance="currentAllowance"
            @onApproveToken="approve"
            :hasAffiliate="detail.is_affiliate_enabled"
            :isMaintaining="isMaintaining"
          ></BuySection>
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↑ プレセール、販売量 > 0 時のみ、価格、販売総数、↑ -->
          <!-- ↑ 残り、開始終了日時、注文数、購入ボタン         ↑ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ====+=========+=========+=========+=========+=========+====== -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↓ パブリックセール、販売量 >0 時のみ、価格、販売総数、↓ -->
          <!-- ↓ 残り、開始終了日時、注文数、購入ボタン              ↓ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <BuySection
            v-if="detail.publicsale_amount > 0"
            type="PUBLIC_SALE"
            :startTime="detail.publicsale_start_time"
            :startTimeInMilliseconds="publicSaleStartTime"
            :endTime="detail.publicsale_end_time"
            :endTimeInMilliseconds="publicSaleEndTime"
            :chainId="detail.chain_id"
            :price="detail.publicsale_price"
            :totalQuantity="publicSaleTotalAmount"
            :sold="detail.publicsale_sold_amount"
            :maxSalePerUser="detail.publicsale_max_purchase"
            :contractId="detail.contract_project_id"
            @buying="onBuying"
            :calendarUrl="calendarUrl(true)"
            :stopped="detail.status === 4"
            :isReady="detail.status_code === 'READY'"
            :isTBA="detail.is_publicsale_tba"
            :isPriceTBA="detail.is_publicsale_price_tba"
            :target="publicSaleTarget"
            :transferUnsoldDisabled="detail.transfer_unsold_disabled"
            :tokenAddress="detail.token_address"
            :allowance="currentAllowance"
            @onApproveToken="approve"
            :hasAffiliate="detail.is_affiliate_enabled"
            :isMaintaining="isMaintaining"
          ></BuySection>
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↑ パブリックセール、販売量 >0 時のみ、価格、販売総数、↑ -->
          <!-- ↑ 残り、開始終了日時、注文数、購入ボタン              ↑ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
        </div>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↑ 購入フォーム。データが有るときのみ表示。↑ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->        
      </div>
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ↑ 画面幅 640px 以上の場合、右列（2:3の3）。↑ -->
      <!-- ↑ 画面幅 640px 未満の場合、画面幅一杯。    ↑ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ========+=========+=========+=========+=========+=========+====== -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ↓ チェーン・規格・コントラクトアドレス。↓ -->
      <!-- ↓ 画像幅 640px 未満時のみ表示。         ↓ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!--
        チェーン・規格・コントラクトアドレスの四角、スマホのみ
          LAYOUT
            width: 361px;
            height: 102.07px;
            border-radius: 8px 0px 0px 0px;
            border: 1px 0px 0px 0px;
          BORDER
            border: 1px solid #EBEBEC
      -->
      <div class="visible sm:hidden mx-4 pl-4 pr-2 pt-3 py-4"
          style="font-family: Noto Sans CJK JP; font-size: 14px;
                color: #1F2023; border: 1px solid #EBEBEC;"
      >
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↓ チェーン スマホ ↓ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!--
          チェーン
            LAYOUT
              height: 19.85px;
            TYPOGRAPHY
              font-family: Noto Sans CJK JP;
              font-size: 14px;
              font-weight: 700; / font-weight: 400;
              line-height: 21px;
            COLOR
              background: #1F2023;
        -->
        <div class="flex items-center mt-2">
          <div style="font-weight: 700;">
            {{ $t('PROJECT_CHAIN_LABEL') }}
          </div> &emsp; 
          <div style=" font-weight: 400;">
            {{ getChainName(detail.chain_id) }}
          </div>
        </div>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↑ チェーン スマホ ↑ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↓ 規格 スマホ ↓ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!--
          規格
            LAYOUT
              height: 19.85px;
              top: 2857.75px; 6.61px(2857.75-(2831.29+19.85))  mt-1.5 margin-top: 0.375rem; /* 6px */採用↓
            TYPOGRAPHY
              font-family: Noto Sans CJK JP;
              font-size: 14px;
              font-weight: 700; / font-weight: 400;
              line-height: 21px;
            COLOR
              background: #1F2023;
        -->
        <div class="flex items-center mt-1.5">
          <div style="font-weight: 700;">{{ $t('STANDARD') }}</div> &emsp;
          <div style="font-weight: 400;">ERC-{{ detail.contract_type }}</div>
        </div>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↑ 規格 スマホ ↑ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↓ コントラクトアドレス スマホ ↓ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!--
          コントラクトアドレス
            LAYOUT
              height: 19.85px;
              top: 2885.16px; 7.56px(2885.16-(2857.75+19.85))  mt-2 margin-top: 0.5rem; /* 8px */採用↓
            TYPOGRAPHY
              font-family: Noto Sans CJK JP;
              font-size: 14px;
              font-weight: 700; / font-weight: 400;
              line-height: 21px;
            COLOR
              background: #1F2023;
        -->
        <div v-if="shortenContractAddress()" class="mt-2">
          <div class="font-bold">
            {{ $t('CONTRACT_ADDRESS') }}
          </div>
          <div class="flex flex-col tablet:flex-col lg:flex-row items-start lg:items-center lg:space-y-0 lg:space-x-4 relative">
            <a 
              :href="scanUrl" 
              target="_blank" 
              rel="noopener noreferrer"
              class="font-normal rounded px-3 py-1 lg:px-1 lg:py-1 text-blue-500 hover:underline"
              style="max-width: 100%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
            >
              {{ shortenContractAddress() }}
            </a>
          </div>
        </div>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↑ コントラクトアドレス スマホ ↑ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
      </div>
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ↑ チェーン・規格・コントラクトアドレス。↑ -->
      <!-- ↑ 画像幅 640px 未満時のみ表示。         ↑ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
    </div>
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- ↑ 画面幅 640px 以上の場合、2:3 の二列にしている。 ↑ -->
    <!-- ↑ 画面幅 640px 未満の場合、一列。                 ↑ -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
  </a-spin>
</template>
<!-- ====+=========+=========+=========+=========+=========+=========+====== -->
<script>
// パンくずリスト
import BreadcrumbList from '@/components/common/BreadcrumbList.vue';
// chain_idとチェーン名を紐づける。
import { CHAIN_ID } from '@/constants/chains.constant';
import GlobalIcon from '@/components/icons/global.icon.vue';
import XIcon from '@/components/icons/x.icon.vue';
// import ShareIcon from '@/components/icons/share.icon.vue';
// import TwitterIcon from '@/components/icons/twitter_outlined.icon.vue';
// import StatusTag from '@/components/nft/NFTDetail/StatusTag.vue';
import BuySection from '@/components/nft/NFTDetail/BuySection.vue';
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import { SITE_URL } from '@/env';
import moment from 'moment';
import { generateCalendarLink } from '@/utils/link.util';
import { getScanUrl } from '@/utils/getScanUrl.util';
// import CombinedTargetBar from '@/components/common/CombinedTargetBar.vue';
// import { getUserReferralCode } from '@/services/blockchain/project';
// ------+---------+---------+---------+---------+---------+---------+---------E
export default {
  name: 'NFTDetailPage',
  // ----+---------+---------+---------+---------+---------+---------+---------E
  components: {
    BuySection,
    // StatusTag,
    BreadcrumbList,
    GlobalIcon,
    XIcon,
    // ShareIcon,
    // TwitterIcon,
    // CombinedTargetBar,
  }, // End of components: {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  data() {
    return {
      id: this.$route.params.id,
      loading: false,
      SITE_URL,
      referral_code: '',
      isMaintaining: false,
    }; // End of return {...}
  }, // End of data() {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  metaInfo() {
    return {
      title: `${this.detail.name} - Zaif INO`,
      // meta: [
      //     {
      //         vmid: "description",
      //         property: "description",
      //         content: this.detail.description,
      //     },
      //     {
      //         property: "og:title",
      //         content: `${this.detail.name} - Zaif INO`,
      //         vmid: "og:title",
      //     },
      //     {
      //         property: "og:site",
      //         content: "Zaif INO",
      //         vmid: "og:site",
      //     },
      //     {
      //         property: "og:description",
      //         vmid: "og:description",

      //         content: this.detail.description,
      //     },
      //     {
      //         property: "og:site_name",
      //         content: `${this.detail.name} - Zaif INO`,
      //         vmid: "og:site_name",
      //     },
      //     {
      //         property: "og:image",
      //         content: this.detail.banner_url,
      //         vmid: "og:image",
      //     },
      //     {
      //         property: "twitter:site",
      //         vmid: "twitter:site",

      //         content: this.detail.twitter_url,
      //     },
      //     {
      //         property: "twitter:card",

      //         vmid: "twitter:card",
      //         content: "summary",
      //     },
      //     {
      //         property: "twitter:image",
      //         content: this.detail.banner_url,
      //         vmid: "twitter:image",
      //     },
      // ],
    }; // End of return {...}
  }, // End of metaInfo() {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  computed: {
    ...mapGetters({
      accessToken: 'wallet/accessToken',
      account: 'wallet/account',
      allowance: 'allowance/allowance',
    }),
    // --+---------+---------+---------+---------+---------+---------+---------E
    ...mapState({
      locale: (state) => state?.setting.locale,
      detail: (state) => state.projects.selectedProject,
      connectedChainId: (state) => state.wallet?.provider?.chainId || -1,
      allowances: (state) => state?.allowance.data || {},
      hierarchy: (state) => state.hierarchy.hierarchy,
      name_en  : (state) => state.hierarchy.name_en,
    }),
    // --+---------+---------+---------+---------+---------+---------+---------E
    presaleStartTime() {
      return moment(this.detail.presale_start_time).valueOf();
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    publicSaleStartTime() {
      return moment(this.detail.publicsale_start_time).valueOf();
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    presaleEndTime() {
      return moment(this.detail.presale_end_time).valueOf();
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    publicSaleEndTime() {
      return moment(this.detail.publicsale_end_time).valueOf();
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    isPresaleEnded() {
      return (
        this.detail.presale_end_time &&
        moment(this.detail.presale_end_time).valueOf() <= new Date().getTime()
      );
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    publicSaleTotalAmount() {
      return (
        Number(this.detail.publicsale_amount) +
        (this.isPresaleEnded && !this.detail.transfer_unsold_disabled
          ? Number(this.detail.presale_amount) - Number(this.detail.presale_sold_amount)
          : 0)
      );
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    canBuy() {
      // is_whitelisted は存在しない。
      return this.detail.is_whitelisted || !this.detail.has_whitelist;
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    formattedDescription() {
      return this.detail.description;
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    presaleTarget() {
      return this.detail.presale_target || this.detail.presale_amount;
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    publicSaleTarget() {
      return this.detail.publicsale_target || this.detail.publicsale_amount;
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    totalSaleAmount() {
      return this.detail.presale_amount + this.detail.publicsale_amount;
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    showName() {
      return this.locale === 'ja' && this.detail.name_ja ? this.detail.name_ja : this.detail.name;
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    showDescription() {
      return this.locale === 'ja' && this.detail.description_ja
        ? this.detail.description_ja
        : this.detail.description;
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    isChainMatched() {
      return this.connectedChainId === this.detail.chain_id;
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    currentAllowance() {
      return this.allowance(this.detail.token_address);
    },
    scanUrl() {
      return getScanUrl(this.detail.chain_id, this.detail.contract_address);
    }
  }, // End of computed: {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  async mounted() {
    // if ([64, 65].includes(Number(this.$route.params.id))) {
    //   this.isMaintaining = true;
    // }
    // --+---------+---------+---------+---------+---------+---------+---------E
    await this.fetchDetail(this.$route.params.id);
    // --+---------+---------+---------+---------+---------+---------+---------E
    console.debug('MOUNTED');
    // --+---------+---------+---------+---------+---------+---------+---------E
    if (this.detail) {
      if (this.detail.is_hidden) {
        this.$router.push('/');
      }
      this.setToConnectChain(this.detail.chain_id);
    }
    // --+---------+---------+---------+---------+---------+---------+---------E
    if (this.detail.hierarchy == undefined) {this.detail.hierarchy=''}
    // --+---------+---------+---------+---------+---------+---------+---------E
    this.setHierarchy({hierarchy: this.detail.hierarchy,
                        name_en: this.detail.name,
                        name_ja: this.detail.name_ja
    });
  }, // End of async mounted() {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  watch: {
    accessToken() {
      console.log('detected change');
      this.fetchDetail(this.$route.params.id);
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    async chainOrAccountChanged(newVal, oldVal) {
      if (newVal[1] !== oldVal[1] || newVal[0] !== oldVal[0]) {
        this.fetchDetail(this.$route.params.id);
        await this.getAllowanceCoin();
      }
    }, // End of async chainOrAccountChanged(newVal, oldVal) {...}
  }, // End of watch: {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  methods: {
    getChainName(id) {
      let stResult = '不明';
      for(let key of Object.keys(CHAIN_ID)) {
        if (CHAIN_ID[key] == id) {
          stResult = key;
          break;
        }
      } // End of for(let key of CHAIN_ID.keys(obj)) {...}
      return stResult;
    }, // End of getChainName(id) {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    // --+---------+---------+---------+---------+---------+---------+---------E
    generateCalendarLink,
    ...mapActions({
      fetchProjectDetail: 'projects/fetchProjectDetail',
      getAllowance: 'allowance/getAllowance',
      approveToken: 'allowance/approveToken',
      setHierarchy: 'hierarchy/setHierarchy',
    }),
    // --+---------+---------+---------+---------+---------+---------+---------E
    ...mapMutations({
      updateProjectDetail: 'projects/UPDATE_SELECTED_PROJECT',
      setModalRequestState: 'wallet/UPDATE_SHOW_MODAL_CONNECT',
      setToConnectChain: 'wallet/UPDATE_TO_CONNECT_CHAIN',
    }),
    // --+---------+---------+---------+---------+---------+---------+---------E
    // コントラクトアドレスを省略するメソッド。現在は全文字表示
    shortenContractAddress() {
      let result;
      if (this.detail.contract_address === undefined) {
        result = false
      } else if (this.detail.contract_address === null) {
        result = false
      } else if (this.detail.contract_address.length < 24) {
        result = this.detail.contract_address;
      } else {
        result = this.detail.contract_address;
      } // End of if (...) {...} ... else {}
      return result;
    }, // End of shortenContractAddress() {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    async fetchDetail(id) {
      this.loading = true;
      await this.fetchProjectDetail(id);
      this.loading = false;
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    async onBuying(res) {
      this.loading = res;
      if (res === false) {
        await this.getAllowanceCoin();
      }
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    calendarUrl(isPublicSale) {
      const saleType = isPublicSale ? 'Public Sale' : 'Presale';
      const startTime = isPublicSale
        ? this.detail.publicsale_start_time
        : this.detail.presale_start_time;
      const endTime = isPublicSale ? this.detail.publicsale_end_time : this.detail.presale_end_time;
      const { description, name, id } = this.detail;
      const refLink = `${SITE_URL}/NFT/${id}`;
      return this.generateCalendarLink(name, saleType, startTime, endTime, description, refLink);
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    async getAllowanceCoin() {
      if (!this.detail.token_address || !this.isChainMatched) {
        return;
      }
      await this.getAllowance({
        tokenAddress: this.detail.token_address,
      });
      console.debug({ allowance: this.currentAllowance });
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    async approve() {
      if (!this.detail.token_address || !this.isChainMatched || !this.$connector.provider) {
        return;
      }
      await this.approveToken({
        tokenAddress: this.detail.token_address,
        provider: this.$connector.provider,
      });
      console.debug({ allowance: this.currentAllowance });
    },
}, // End of methods: {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  destroyed() {
    this.updateProjectDetail({});
  },
};
</script>
<!-- ====+=========+=========+=========+=========+=========+=========+====== -->
<style
  lang="scss"
  scoped
>
/* .nft-detail-page-img-container { */
/*   max-width: 1140px; */
/*   @apply mx-auto; */
/* } */
::v-deep .nft-detail-page {
  @apply overflow-hidden;
  @apply px-4;
  @screen md {
    @apply px-4;
  }
  @screen xl {
    width: 1140px;
    @apply px-0;
  }
  .description {
    a {
      color: #40a9ff;
    }
  }
  &-breadcrumb {
    .ant-breadcrumb {
      span {
        .ant-breadcrumb-link {
          @apply text-white;
          @apply font-medium;
        }
        .ant-breadcrumb-separator {
          color: #fff;
        }
      }
    }
  }
  .combined-target-bar {
    @screen xxs {
      padding-left: 16px;
      padding-right: 16px;
    }
    @screen md {
      padding-left: 32px;
      padding-right: 32px;
    }
    @apply px-1;
    @apply mb-4;
  }
}
</style>
