<template>
  <div :class="['buy-section relative', transferUnsoldDisabled ? 'xs:pb-36 pb-24' : '']">
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- ↓ 画面一杯                                            ↓ -->
    <!-- ↓「プリセール」「パブリックセール」購入フォームヘッダ ↓ -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!--
    パブリックセールの部分の四角
      スマホ
        LAYOUT
          width: 359px;  PC は width: 688px; 他はPCと同じ
          height: 23px;
          border-radius: 16px 16px 0px 0px;
        COLOR
          background: #9B9DA133;
    パブリックセールの文字列
      スマホ PC 共通
        LAYOUT
          height: 21px;
        TYPOGRAPHY
          font-family: Noto Sans CJK JP;
          font-size: 12px;
          font-weight: 700;
          line-height: 21px;
        COLOR
          background: #1F2023;
    -->
    <div class="mx-2 mt-6 sm:mx-0 flex items-center uppercase"
        style="height: 23px;  background-color: #9B9DA133;
              border-radius: 16px 16px 0px 0px;
              font-family: Noto Sans CJK JP; font-size: 12px;
              font-weight: 700; color: #1F2023"
    >
      <!-- プレセールとパブリックセールで文字色を変えている。
      <span
        :class="[type === 'PRESALE' ? 'text-presaleColor'
                                    : 'text-publicSaleColor']"
      > &ensp; {{ $t(types[type].title) }}
      -->
      &emsp; {{ $t(types[type].title) }}
    </div>
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- ↑ 画面一杯                                            ↑ -->
    <!-- ↑「プリセール」「パブリックセール」購入フォームヘッダ ↑ -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- +=========+=========+=========+=========+=========+=========+====== -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- ↓ 画面幅 640px 以上の場合、二列(12列にして6列と6列)。↓ -->
    <!-- ↓ 画面幅 640px 未満の場合、一列。                    ↓ -->
    <!-- ↓「プリセール」「パブリックセール」購入フォーム      ↓ -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!--
      セール入力フォーム
        スマホ
          LAYOUT
            width: 359px;  PCは width: 688px;
            height: 491px; PCは height: 272px; 他はPCも同じ
            border-radius: 24px 0px 0px 0px;
            border: 1px 0px 0px 0px;
          BORDER
            border: 1px solid #EBEBEC
    -->
    <div class="mx-2 sm:mx-0 sm:grid grid-cols-12 gap-x-0 border"
        style="border: 1px solid #EBEBEC"
    >
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ↓ 画面幅 640px 以上の場合、左列（6:6の6）。                ↓  -->
      <!-- ↓ 画面幅 640px 未満の場合、画面横一杯。                    ↓  -->
      <!-- ↓「価格」「総販売数・残り」「販売開始日時」「販売終了日時」↓ -->
      <!-- ↓ 目標販売数と販売数のTargetBar                            ↓ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <div class="mx-2 sm:mx-0 px-0 sm:px-4 sm:col-span-6 content-between">
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↓ 左列で上に寄せる。                                ↓ -->
        <!-- ↓ 価格、総販売数、残り、販売開始日時、販売終了日時、↓ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <div class="mt-4">
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↓「価格」ラベルと価格、通貨単位 ↓ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!--
            価格
              SmartPhone PC共通
                LAYOUT
                  height: 21px;
                TYPOGRAPHY_1
                  font-family: Inter;
                  font-size: 18px;  text-lg / font-size: 24px;  text-2xl
                  font-weight: 700;
                  line-height: 21px;
          -->
          <div class="flex items-end"
            style="font-family: Inter; font-weight: 700;"
          >
            <div class="text-lg text-black">{{ $t('PRICE') }}</div> &emsp;
            <div class="text-2xl price">{{ price }}</div>
            &ensp;
            <div class="text-lg price">{{ symbol }}</div>
          </div>
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↑「価格」ラベルと価格、通貨単位 ↑ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ====+=========+=========+=========+=========+=========+====== -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↓「総販売数」ラベルと総販売数、「残り」ラベルと残り ↓ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <div class="flex items-end mt-3"
              style="font-family: Inter; font-size: 16px; font-weight: 700;
                    color: #000000;"
          >
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↓ 「総販売数」ラベルと総販売数 ↓ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!--
              SmartPhone PC 共通
                 LAYOUT
                    height: 21px;
                    top: 79px;  12px(79-(46+21)  mt-3 margin-top: 0.75rem; /* 12px */採用↑
                  TYPOGRAPHY
                    font-family: Inter;
                    font-size: 16px; text-base
                    font-weight: 500;
                    line-height: 21px;
                  COLOR
                    background: #000000;
            -->
            <div style="font-weight: 500">
              {{ $t('SALE_AMOUNT') }} &ensp; {{ totalQuantity }} &emsp;
            </div>
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↑ 「総販売数」ラベルと総販売数 ↑ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↓ 「残り」ラベルと残り ↓ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!--
              SmartPhone PC 共通
                LAYOUT
                    height: 21px;
                    top: 79px;  12px(79-(46+21)  mt-3 margin-top: 0.75rem; /* 12px */採用↑
                  TYPOGRAPHY
                    font-family: Inter;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 21px;
                  COLOR
                    background: #000000;
            -->
            <div style="font-weight: 700">
              {{ $t('LEFTOVER') }} &ensp; {{ totalQuantity - sold }}
            </div>
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↑ 「残り」ラベルと残り ↑ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
          </div>
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↑「総販売数」ラベルと総販売数、「残り」ラベルと残り ↑ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ====+=========+=========+=========+=========+=========+====== -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↓「販売開始日時」ラベルと販売開始日時 ↓ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!--
            販売開始日時
              SmartPhone PC共通
                LAYOUT
                  height: 21px;
                  top: 110px;  10px(110-(79+21))  mt-2.5 margin-top: 0.625rem; /* 10px */採用 スマホ
                  top: 127px;  15px(127-(91+21))  mt-3.5 margin-top: 0.875rem; /* 14px */採用 PC
                TYPOGRAPHY
                  font-family: Noto Sans CJK JP;
                  font-size: 14px;
                  font-weight: 700; / font-weight: 400;
                  line-height: 21px;
                  text-align: left;
          -->
          <div class="flex mt-2.5 sm:mt-3.5"
              style="font-family: Noto Sans CJK JP; font-size: 14px;
                    color: #1F2023;"
          >
            <div style="font-weight: 700;">{{ $t('STARTDATE') }}</div> &ensp;
            <div v-if="!isTBA" style="font-weight: 400;">
              {{ startTime | toJST('YYYY/MM/DD HH:mm (ddd)') }}
            </div>
            <div v-else>{{ $t('TBA') }}</div>
          </div>
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↑「販売開始日時」ラベルと販売開始日時 ↑ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ====+=========+=========+=========+=========+=========+====== -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↓「販売終了終了」ラベルと販売終了日時 ↓ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!--
            height: 21px;
            top: 136px;  5px(136-(110+21))  mt-1.5 margin-top: 0.375rem; /* 6px */採用 スマホ
            top: 156px;  8px(156-(127+21))  mt-2 margin-top: 0.5rem; /* 8px */ PC
            font-family: Noto Sans CJK JP;
            font-size: 14px;
            font-weight: 700; / font-weight: 400;
            line-height: 21px;
            background: #1F2023;
            -->
          <div class="flex mt-1.5 sm:mt-2 mb-3"
              style="font-family: Noto Sans CJK JP; font-size: 14px;
                    color: #1F2023;"
          >
            <div style="font-weight: 700;">{{ $t('ENDDATE') }}</div> &ensp;
            <div v-if="!isTBA" style="font-weight: 400;">
              {{ endTime | toJST('YYYY/MM/DD HH:mm (ddd)') }}
            </div>
            <div v-else>{{ $t('TBA') }}</div>
          </div>
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↑「販売終了終了」ラベルと販売終了日時 ↑ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
        </div>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↑ 左列で上に寄せる。                                ↑ -->
        <!-- ↑ 価格、総販売数、残り、販売開始日時、販売終了日時、↑ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ======+=========+=========+=========+=========+=========+====== -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↓ 左列で下に寄せる。ＮＦＴ全量と販売済み量のTargetBar ↓ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!--
        <div
          v-if="transferUnsoldDisabled"
          class="absolute bottom-0 w-full"
        >
          <TargetBar
            class="scaler"
            :sold="sold"
            :target="target"
            :isPresale="type === 'PRESALE'"
          ></TargetBar>
        -->
        <!--
        <div class="mb-4">
          <TargetBar
            class="col-span-6 mb-10"
            :sold="sold"
            :target="target"
            :isPresale="type === 'PRESALE'"
          ></TargetBar>
          <div
            v-if="sold / totalQuantity >= 0.8"
            class="absolute -bottom-4 right-0 border rounded-2xl text-center w-36 text-base font-medium"
          >{{ sold }}/{{ totalQuantity }}</div>
        </div>
        -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↑ 左列で下に寄せる。ＮＦＴ全量と販売済み量のTargetBar ↑ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
      </div>
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ↑ 画面幅 640px 以上の場合、左列（6:6の6）。                ↑  -->
      <!-- ↑ 画面幅 640px 未満の場合、画面横一杯。                    ↑  -->
      <!-- ↑「価格」「総販売数・残り」「販売開始日時」「販売終了日時」↑ -->
      <!-- ↑ 目標販売数と販売数のTargetBar                            ↑ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ========+=========+=========+=========+=========+=========+====== -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ↓ スマホのみ。数量入力部分の上の線 ↓ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!--
        スマホのみ、数量入力部分の上の線
          LAYOUT
            width: 325px;
            height: 0px;
            border: 1px 0px 0px 0px;
            opacity: 0px;
          BORDER
            border: 1px solid #F0F0F1
      -->
      <hr class="mx-auto mt-2 visible sm:hidden"
          style="width: 325px; border: 1px solid #F0F0F1"
      >
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ↑ スマホのみ。数量入力部分の上の線 ↑ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ========+===========+===========+===========+===========+======== -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ↓ 画面幅 640px 以上の場合、右列（6:6の6）。 ↓  -->
      <!-- ↓ 画面幅 640px 未満の場合、画面横一杯。     ↓  -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <div class="pb-6 sm:border-l sm:col-span-6">
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↓「数量」入力テキストボックス、                             ↓ -->
        <!-- ↓「暗号資産で購入」ボタン、「クレジットカードで購入」ボタン ↓ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <div class="w-80 sm:w-60 mx-auto">
          <ValidationObserver ref="buyForm">
            <form @submit.prevent="onSubmitBuy" class="mx-auto">
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓「数量」ラベルと数量入力テキストボックス ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!--
                height: 38px;
                top: 243px;  16px(243-(227+0))  mt-4 margin-top: 1rem; /* 16px */採用
              -->
              <div class="mt-4 ml-0 items-center">
                <ValidationProvider
                  :rules="{
                    max_value: maxPurchasableAmount,
                    min_value: 1,
                    required: true,
                  }"
                  v-slot="{ errors }"
                  name="QUANTITY"
                >
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <!-- ↓「数量］テキストと数量入力テキストボックス ↓ -->
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <div class="flex items-center">
                    <!-- ----+---------+---------+---------+---------+------ -->
                    <!-- ↓「数量］テキスト ↓ -->
                    <!-- ----+---------+---------+---------+---------+------ -->
                    <!--
                      SmartPhone PC共通
                        LAYOUT
                          width: 32px; 54px以上にしないと「数量」が縦表示になる
                          height: 21px;
                        TYPOGRAPHY
                          font-family: Inter;
                          font-size: 16px;
                          font-weight: 400;
                          line-height: 21px;
                        COLOR
                          background: #1F2023;
                    -->
                    <div style="width: 100px; font-family: Inter; font-size:16px;
                                font-weight: 400; color:  #1F2023;"
                    >
                      {{ $t('SUPPLY') }} &nbsp;
                    </div>
                    <!-- ----+---------+---------+---------+---------+------ -->
                    <!-- ↑「数量］テキスト ↑ -->
                    <!-- ----+---------+---------+---------+---------+------ -->
                    <!-- ----+---------+---------+---------+---------+------ -->
                    <!-- ↓ 数量入力テキストボックス ↓ -->
                    <!-- ----+---------+---------+---------+---------+------ -->
                    <InputNumberCustomColor
                      :disabled="sold >= totalQuantity || isPriceTBA"
                      type="text"
                      v-model="quantity"
                      :decimals="0"
                      inputClass="quantity-input"
                    ></InputNumberCustomColor>
                    <!-- ----+---------+---------+---------+---------+------ -->
                    <!-- ↑ 数量入力テキストボックス ↑ -->
                    <!-- ----+---------+---------+---------+---------+------ -->
                  </div>
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <!-- ↑「数量］テキストと数量入力テキストボックス ↑ -->
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <!-- ↓ 数量が不適切な場合のエラーメッセージ ↓ -->
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <div
                      class="text-red-500 text-sm mt-2 text-left"
                      v-if="errors && errors.length > 0 && sold < totalQuantity"
                  >{{ errors[0] }}</div>
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <!-- ↑ 数量が不適切な場合のエラーメッセージ ↑ -->
                  <!-- ------+---------+---------+---------+---------+------ -->
                </ValidationProvider>
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑「数量」ラベルと数量入力テキストボックス ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div class="buy-buttons -mt-3 flex justify-center" >
                <!-- --------+---------+---------+---------+---------+------ -->
                <!-- ↓「セール終了」ボタン。status === 4 で表示。↓ -->
                <!-- --------+---------+---------+---------+---------+------ -->
                <div
                  class="mt-2 w-full"
                  v-if="stopped"
                >
                  <button
                    disabled
                    class="w-80 sm:w-60 uppercase disabled:cursor-not-allowed
                          rounded-full font-medium
                          xl:text-sm lg:text-sm md:text-sm sm:text-sm text-xl
                          xl:px-4 lg:px-3 md:px-2 sm:px-2 px-5 h-10 mt-4
                          disabled:bg-disabled bg-white text-black 
                          hover:opacity-70 disabled:text-white"
                    style="font-family: Inter;"
                >{{ $t('BUTTON_SALE_STOPPED') }}</button>
                </div>
                <!-- --------+---------+---------+---------+---------+------ -->
                <!-- ↑「セール終了」ボタン。status === 4 で表示。↑ -->
                <!-- --------+---------+---------+---------+---------+------ -->
                <!-- --------+---------+---------+---------+---------+------ -->
                <!-- ↓「開始準備」ボタン。status_code === 'READY' で表示。↓ -->
                <!-- --------+---------+---------+---------+---------+------ -->
                <div
                  class="mt-2 w-full"
                  v-else-if="isReady"
                >
                  <button
                    disabled
                    class="w-80 sm:w-60 uppercase disabled:cursor-not-allowed
                          rounded-full font-medium
                          xl:text-sm lg:text-sm md:text-sm sm:text-sm text-xl
                          xl:px-4 lg:px-3 md:px-2 sm:px-2 px-5 h-10 mt-4
                          disabled:bg-disabled bg-white text-black
                          hover:opacity-70 disabled:text-white"
                    style="font-family: Inter;"
                  >{{ $t('BUTTON_READY') }}</button>
                </div>
                <!-- --------+---------+---------+---------+---------+------ -->
                <!-- ↑「開始準備」ボタン。status_code === 'READY' で表示。↑ -->
                <!-- --------+---------+---------+---------+---------+------ -->
                <!-- --------+---------+---------+---------+---------+------ -->
                <!-- ↓「アローリスト対象外」ボタン ↓ -->
                <!-- --------+---------+---------+---------+---------+------ -->
                <div
                  class="mt-2 w-full"
                  v-else-if="!isWhitelisted"
                >
                  <button
                    disabled
                    class="w-80 sm:w-60 uppercase disabled:cursor-not-allowed
                          rounded-full font-medium
                          xl:text-sm lg:text-sm md:text-sm sm:text-sm text-sm
                          xl:px-4 lg:px-3 md:px-2 sm:px-2 px-5 h-10 mt-4
                          disabled:bg-disabled bg-white text-black
                          hover:opacity-70 disabled:text-white"
                    style="font-family: Inter;"
                  >{{ $t('BUTTON_NOT_WHITELISTED') }}</button>
                </div>
                <!-- --------+---------+---------+---------+---------+------ -->
                <!-- ↑「アローリスト対象外」ボタン ↑ -->
                <!-- --------+---------+---------+---------+---------+------ -->
                <!-- --------+---------+---------+---------+---------+------ -->
                <!-- ↓「開始準備中」ボタン ↓ -->
                <!-- --------+---------+---------+---------+---------+------ -->
                <div
                  class="mt-2 w-full"
                  v-else-if="new Date().getTime() < startTimeInMilliseconds"
                >
                  <button
                    disabled
                    class="w-80 sm:w-60 uppercase disabled:cursor-not-allowed
                          rounded-full font-medium
                          xl:text-sm lg:text-sm md:text-sm sm:text-sm text-sm
                          xl:px-4 lg:px-3 md:px-2 sm:px-2 px-5 h-10 mt-4
                          disabled:bg-disabled bg-white text-black
                          hover:opacity-70 disabled:text-white"
                    style="font-family: Inter;"
                  >{{ $t('BUTTON_EVENT_NOT_STARTED') }}</button>
                </div>
                <!-- --------+---------+---------+---------+---------+------ -->
                <!-- ↑「開始準備中」ボタン ↑ -->
                <!-- --------+---------+---------+---------+---------+------ -->
                <!-- --------+---------+---------+---------+---------+------ -->
                <!-- ↓「暗号資産で購入」ボタンや「承認」ボタン ↓ -->
                <!-- --------+---------+---------+---------+---------+------ -->
                <div
                  v-else-if="
                    sold < totalQuantity && new Date().getTime() < endTimeInMilliseconds
                  "
                  class="buy-btns mt-2"
                >
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <!-- ↓ 1st「暗号資産で購入」ボタン ↓ -->
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <!--
                    「暗号資産で購入」ボタンの四角
                      SmartPhone
                        LAYOUT
                          width: 327px;  w-80 width: 20rem; /* 320px */
                          height: 46px;
                          top: 293px;  12px(293-(243+38))  mt-3.5 margin-top: 0.875rem; /* 14px */
                          left: 148.67px;  3.67px(148.67-(129+16)) ml-1 margin-left: 0.25rem; /* 4px */採用
                          border-radius: 50px 0px 0px 0px;
                        COLOR
                          background: #FF0085;
                      PC
                        LAYOUT
                          width: 211px;  w-56 width: 14rem; /* 224px */ w-60にした
                          height: 45.53px; 他はSmartPhoneと同じ
                          top: 91px;  17px(91-(36+38))  mt-4 margin-top: 1rem; /* 16px */採用↓
                          left: 599.67px; 3.67px(599.67-(580+16)) ml-1 margin-left: 0.25rem; /* 4px */
                  -->
                  <!--
                  <button
                    v-if="!isMatchChainId"
                    type="button"
                    @click.prevent="handleMetamask"
                    class="w-full disabled:cursor-not-allowed rounded-xl font-medium xl:text-xl lg:text-lg md:text-sm sm:text-sm text-xs xl:px-4 lg:px-3 md:px-2 sm:px-2 h-10 px-5 disabled:bg-disabled bg-primary text-white hover:opacity-70 disabled:text-white mt-5"
                  >
                  -->
                  <button
                    v-if="!isMatchChainId"
                    type="button"
                    @click.prevent="handleMetamask('1')"
                    class="w-80 sm:w-60 disabled:cursor-not-allowed rounded-full
                          disabled:bg-disabled bg-colorButtonBuy
                          hover:opacity-70 mt-4"
                    style="height: 46px;"
                  >
                    <div class="flex justify-center items-center">
                      <!--
                        maticアイコン枠
                          width: 16px;
                          height: 16px;
                          left: 129px; スマホ
                          left: 580px; PC
                      -->
                      <component
                        :is="iconSvg"
                        :class="iconCss"
                        style="width: 16px; height: 16px; margin-top: -2px;"
                        ></component>
                      <!--
                        「暗号資産で購入」文字列
                            LAYOUT
                              height: 17px;
                              left: 148.67px;  3.67px(148.67-(129+16)) ml-1 margin-left: 0.25rem; /* 4px */スマホ採用
                              left: 599.67px; 3.67px(599.67-(580+16)) ml-1 margin-left: 0.25rem; /* 4px */PC
                            TYPOGRAPHY
                                font-family: Inter;
                                font-size: 14px;
                                font-weight: 600;
                                line-height: 16.94px;
                            COLOR
                              background: #FFFFFF;
                      -->
                      <div class="ml-1"
                          style="font-family: Inter; font-size: 14px;
                                font-weight: 600; color: #FFFFFF;"
                      >
                        {{ $t('BUTTON_BUY') }}
                      </div>
                    </div>
                  </button>
                  <!--引き換えアドレスを取得するモーダルを表示後暗号資産で購入--> 
                  <button
                    v-else-if="projectDetail.exchange_wallet_info === 1"
                    :disabled="isMaintaining"
                    type="submit"
                    @click.prevent="showModalAfterSwitch(true,'1')"
                    class="w-80 sm:w-60 disabled:cursor-not-allowed rounded-full
                          disabled:bg-disabled bg-colorButtonBuy
                          hover:opacity-70 mt-4"
                    style="height: 46px; font-family: Inter; font-size: 14px;
                                  font-weight: 600; color: #FFFFFF;"
                  >
                  <div class="flex justify-center items-center">
                      <!--
                        maticアイコン枠
                          width: 16px;
                          height: 16px;
                          left: 129px; スマホ
                          left: 580px; PC
                      -->
                      <component
                      :is="iconSvg"
                      :class="iconCss"
                      style="width: 16px; height: 16px; margin-top: -2px;"
                      ></component>
                      <div class="ml-1"
                        style="font-family: Inter; font-size: 14px;
                              font-weight: 600; color: #FFFFFF;"
                      >
                        {{ $t('BUTTON_BUY') }}
                      </div>
                    </div>
                  </button>
                  <!--郵送情報を取得するモーダルを表示後暗号資産で購入--> 
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <!-- ↑ 1st「暗号資産で購入」ボタン ↑ -->
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <a-spin
                    :spinning="loadingAllowance && tokenAddress"
                    v-else
                  >
                    <!-- ----+---------+---------+---------+---------+------ -->
                    <!-- ↓「承認」ボタン ↓ -->
                    <!-- ----+---------+---------+---------+---------+------ -->
                    <button
                      v-if="tokenAddress && isLess(allowance, totalPrice || '0')"
                      type="button"
                      @click="approveToken"
                      class="w-80 sm:w-60 disabled:cursor-not-allowed rounded-full
                          disabled:bg-disabled bg-colorButtonBuy
                          hover:opacity-70 mt-4"
                    style="height: 46px; font-family: Inter; font-size: 14px;
                                  font-weight: 600; color: #FFFFFF;"
                  >
                    {{ $t('APPROVE_BUTTON') }}</button>
                    <!-- ----+---------+---------+---------+---------+------ -->
                    <!-- ↑「承認」ボタン ↑ -->
                    <!-- ----+---------+---------+---------+---------+------ -->
                    <!-- ----+---------+---------+---------+---------+------ -->
                    <!-- ↓ 2nd「暗号資産で購入」ボタン ↓ -->
                    <!-- ----+---------+---------+---------+---------+------ -->
                    <!--
                    <button
                      v-else-if="projectDetail.shipping_form === 1"
                      :disabled="isMaintaining"
                      type="submit"
                      @click.prevent="showModalAfterSwitch"
                      class="w-full disabled:cursor-not-allowed rounded-xl font-medium xl:text-xl lg:text-lg md:text-sm sm:text-sm text-xs xl:px-4 lg:px-3 md:px-2 sm:px-2 h-10 px-5 disabled:bg-disabled bg-primary text-white hover:opacity-70 disabled:text-white mt-5"
                    >
                    -->
                    <button
                      v-else-if="projectDetail.shipping_form === 1"
                      :disabled="isMaintaining"
                      type="submit"
                      @click.prevent="showModalAfterSwitch"
                      class="w-80 sm:w-60 disabled:cursor-not-allowed
                            rounded-full
                            disabled:bg-disabled bg-colorButtonBuy
                            hover:opacity-70 mt-4"
                      style="height: 46px;"
                    >
                      <div class="flex justify-center items-center">
                        <!--
                          maticアイコン枠
                            width: 16px;
                            height: 16px;
                            left: 129px; スマホ
                            left: 580px; PC
                        -->
                        <component
                          :is="iconSvg"
                          :class="iconCss"
                          style="width: 16px; height: 16px; margin-top: -2px;"
                        ></component>
                        <!--
                          「暗号資産で購入」文字列
                              LAYOUT
                                height: 17px;
                                left: 148.67px;  3.67px(148.67-(129+16)) ml-1 margin-left: 0.25rem; /* 4px */スマホ採用
                                left: 599.67px; 3.67px(599.67-(580+16)) ml-1 margin-left: 0.25rem; /* 4px */PC
                              TYPOGRAPHY
                                  font-family: Inter;
                                  font-size: 14px;
                                  font-weight: 600;
                                  line-height: 16.94px;
                              COLOR
                                background: #FFFFFF;
                        -->
                        <div class="ml-1"
                            style="font-family: Inter; font-size: 14px;
                                  font-weight: 600; color: #FFFFFF;"
                        >
                          {{ $t('BUTTON_BUY') }}
                        </div>
                      </div>
                    </button>
                    <!-- ----+---------+---------+---------+---------+------ -->
                    <!-- ↑ 2nd「暗号資産で購入」ボタン ↑ -->
                    <!-- ----+---------+---------+---------+---------+------ -->
                    <!-- ----+---------+---------+---------+---------+------ -->
                    <!-- ↓ 3rd「暗号資産で購入」ボタン ↓ -->
                    <!-- ----+---------+---------+---------+---------+------ -->
                    <!--
                      「暗号資産で購入」ボタンの四角
                        SmartPhone
                          LAYOUT
                            width: 327px;  w-80 width: 20rem; /* 320px */
                            height: 46px;
                            top: 293px;  12px(293-(243+38))  mt-3.5 margin-top: 0.875rem; /* 14px */
                            left: 148.67px;  3.67px(148.67-(129+16)) ml-1 margin-left: 0.25rem; /* 4px */採用
                            border-radius: 50px 0px 0px 0px;
                          COLOR
                            background: #FF0085;
                        PC
                          LAYOUT
                            width: 211px;  w-56 width: 14rem; /* 224px */ w-60にした
                            height: 45.53px; 他はSmartPhoneと同じ
                            top: 91px;  17px(91-(36+38))  mt-4 margin-top: 1rem; /* 16px */採用↓
                            left: 599.67px; 3.67px(599.67-(580+16)) ml-1 margin-left: 0.25rem; /* 4px */
                    -->
                    <!--
                    <button
                      v-else
                      :disabled="isMaintaining"
                      type="submit"
                      class="w-full disabled:cursor-not-allowed rounded-xl font-medium xl:text-xl lg:text-lg md:text-sm sm:text-sm text-xs xl:px-4 lg:px-3 md:px-2 sm:px-2 h-10 px-5 disabled:bg-disabled bg-primary text-white hover:opacity-70 disabled:text-white mt-5"
                    >
                    -->
                    <button
                      v-else
                      :disabled="isMaintaining"
                      type="submit"
                      class="w-80 sm:w-60 disabled:cursor-not-allowed
                            rounded-full
                            disabled:bg-disabled bg-colorButtonBuy 
                            hover:opacity-70 mt-4"
                      style="height: 46px;"
                    >
                      <div class="flex justify-center items-center">
                        <!--
                          maticアイコン枠
                            width: 16px;
                            height: 16px;
                            left: 129px; スマホ
                            left: 580px; PC
                        -->
                        <component
                          :is="iconSvg"
                          :class="iconCss"
                          style="width: 16px; height: 16px; margin-top: -2px;"
                        ></component>
                        <!--
                          「暗号資産で購入」文字列
                              LAYOUT
                                height: 17px;
                                left: 148.67px;  3.67px(148.67-(129+16)) ml-1 margin-left: 0.25rem; /* 4px */スマホ採用
                                left: 599.67px; 3.67px(599.67-(580+16)) ml-1 margin-left: 0.25rem; /* 4px */PC
                              TYPOGRAPHY
                                  font-family: Inter;
                                  font-size: 14px;
                                  font-weight: 600;
                                  line-height: 16.94px;
                              COLOR
                                background: #FFFFFF;
                        -->
                        <div class="ml-1"
                            style="font-family: Inter; font-size: 14px;
                                  font-weight: 600; color: #FFFFFF;"
                        >
                          {{ $t('BUTTON_BUY') }}
                        </div>
                      </div>
                    </button>
                    <!-- ----+---------+---------+---------+---------+------ -->
                    <!-- ↑ 3rd「暗号資産で購入」ボタン ↑ -->
                    <!-- ----+---------+---------+---------+---------+------ -->
                  </a-spin>
                  <shippingModalForm
                    v-if="showModal"
                    :quantity="quantity"
                    @close="showModal = false"
                    @submitBuy.prevent="onSubmitBuy"
                  />
                </div>
                <!-- --------+---------+---------+---------+---------+------ -->
                <!-- ↑「暗号資産で購入」ボタンや「承認」ボタン ↑ -->
                <!-- --------+---------+---------+---------+---------+------ -->
                <!-- --------+---------+---------+---------+---------+------ -->
                <!-- ↓「終了」ボタン ↓ -->
                <!-- --------+---------+---------+---------+---------+------ -->
                <div
                  class="mt-2 w-full"
                  v-else-if="new Date().getTime() >= endTimeInMilliseconds"
                >
                  <button
                    disabled
                    class="w-80 sm:w-60 disabled:cursor-not-allowed rounded-full
                          font-medium text-base
                          xl:px-4 lg:px-3 md:px-2 sm:px-2 px-5 h-10 mt-4
                          disabled:bg-disabled bg-white text-black
                          hover:opacity-70 disabled:text-white"
                     style="font-family: Inter;"
                  >{{ $t('BUTTON_END') }}</button>
                </div>
                <!-- --------+---------+---------+---------+---------+------ -->
                <!-- ↑「終了」ボタン ↑ -->
                <!-- --------+---------+---------+---------+---------+------ -->
                <!-- --------+---------+---------+---------+---------+------ -->
                <!-- ↓「完売」ボタン ↓ -->
                <!-- --------+---------+---------+---------+---------+------ -->
                <div
                  v-else-if="sold >= totalQuantity"
                  class="mt-2 buy-btns w-full"
                >
                  <button
                    disabled
                    class="w-80 sm:w-60 disabled:cursor-not-allowed rounded-full
                          font-medium
                          xl:text-xl lg:text-lg md:text-sm sm:text-sm text-xl
                          xl:px-4 lg:px-3 md:px-2 sm:px-2 px-5 h-10 mt-4
                          disabled:bg-disabled text-black
                          hover:opacity-70"
                    style="font-family: Inter; color: #FFFFFF;"
                  >{{ $t('BUTTON_SOLD_OUT') }}</button>
                </div>
                <!-- --------+---------+---------+---------+---------+------ -->
                <!-- ↑「完売」ボタン ↑ -->
                <!-- --------+---------+---------+---------+---------+------ -->
              </div>
            </form>
            <!-- ==+=========+=========+=========+=========+=========+====== -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↓「クレジットカードで購入」ボタン ↓ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <form @submit.prevent="onSubmitPiement"
                  class="flex justify-center"
            >
              <!--
                「クレジットカードで購入」ボタンの四角
                   SmartPhone
                     LAYOUT
                       width: 327px; PC は  width: 211px; 他はスマホと同じ
                       height: 46px;
                       top: 351px; 36px(351-(293+22))  mt-9 margin-top: 2.25rem; /* 36px */ スマホ
                       top: 145px;  8.47px(145-(45.53+91))  mt-2 margin-top: 0.5rem; /* 8px */ PC 採用↑
                       border-radius: 50px 0px 0px 0px;
                     COLOR
                       background: #ACCB39;
              -->
              <!--
              <button
                v-if = "!isReady && !stopped && projectDetail.payment === 1 && sold < totalQuantity && new Date().getTime() < endTimeInMilliseconds
                      && new Date().getTime() > startTimeInMilliseconds && isWhitelisted"
                :disabled="isMaintaining"
                type="submit"
                :class="[
                  'w-3/4 disabled:cursor-not-allowed rounded-xl font-medium lg:text-lg md:text-sm sm:text-sm text-2xs xl:px-4 lg:px-3 md:px-2 sm:px-2 h-10 px-5 disabled:bg-disabled bg-green-500 text-white hover:opacity-70 disabled:text-white mt-6'
                , locale === 'en' ? 'xl:text-sm' : 'xl:text-xl',]"
              >{{ $t('BUTTON_PIMENT_BUY') }}</button>
              -->
              <button
                v-if = "!isReady && !stopped && projectDetail.payment === 1 && sold < totalQuantity && new Date().getTime() < endTimeInMilliseconds
                      && new Date().getTime() > startTimeInMilliseconds && isWhitelisted"
                :disabled="isMaintaining"
                type="submit"
                :class="[
                  'w-80 sm:w-60 disabled:cursor-not-allowed rounded-full disabled:bg-disabled bg-colorButtonPimentBuy hover:opacity-70 mt-2'
                ]"
                style="height: 46px;"
              >
                <div class="flex justify-center items-center">
                  <!--
                    「クレジットカードで購入」ボタン
                      width: 16px;
                      height: 16px;
                      left: 101px;スマホ
                      left: 556px;PC
                  -->
                  <img src="/images/piment_buy.png"
                      style="width: 16px; height: 16px;" alt="ALT" />
                  <!--
                    「クレジットカードで購入」ボタンのテキスト
                         LAYOUT
                          height: 17px;
                          left: 120.92px; 3.91px(120.91-(101+16)) ml-1 margin-left: 0.25rem; /* 4px */スマホ採用
                          left: 575.92px; 3.92px(575.92-(556+16)) ml-1 margin-left: 0.25rem; /* 4px */
                        TYPOGRAPHY
                          font-family: Inter;
                          font-size: 14px;
                          font-weight: 600;
                          line-height: 16.94px;
                        COLOR
                          background: #FFFFFF;
                  -->
                  <div class="ml-1"
                    style="font-family: Inter; font-size: 14px;
                          font-weight: 600; color: #FFFFFF;"
                  >
                    {{ $t('BUTTON_PIMENT_BUY') }}
                  </div>
                </div>
              </button>
              <!--引き換えアドレスを取得するモーダルを表示後クレジットカードで購入--> 
              <button
                v-else-if = "!isReady && !stopped && projectDetail.exchange_wallet_info === 1 && sold < totalQuantity && new Date().getTime() < endTimeInMilliseconds
                        && new Date().getTime() > startTimeInMilliseconds && isWhitelisted"
                  :disabled="isMaintaining"
                  @click.prevent="showModalAfterSwitch(true,'2')"
                  type="submit"
                  :class="[
                  'w-80 sm:w-60 disabled:cursor-not-allowed rounded-full disabled:bg-disabled bg-colorButtonPimentBuy hover:opacity-70 mt-2'
                ]"
                style="height: 46px;"
              >
                <div class="flex justify-center items-center">
                  <!--
                    「クレジットカードで購入」ボタン
                      width: 16px;
                      height: 16px;
                      left: 101px;スマホ
                      left: 556px;PC
                  -->
                  <img src="/images/piment_buy.png"
                      style="width: 16px; height: 16px;" alt="ALT" />
                  <!--
                    「クレジットカードで購入」ボタンのテキスト
                         LAYOUT
                          height: 17px;
                          left: 120.92px; 3.91px(120.91-(101+16)) ml-1 margin-left: 0.25rem; /* 4px */スマホ採用
                          left: 575.92px; 3.92px(575.92-(556+16)) ml-1 margin-left: 0.25rem; /* 4px */
                        TYPOGRAPHY
                          font-family: Inter;
                          font-size: 14px;
                          font-weight: 600;
                          line-height: 16.94px;
                        COLOR
                          background: #FFFFFF;
                  -->
                  <div class="ml-1"
                    style="font-family: Inter; font-size: 14px;
                          font-weight: 600; color: #FFFFFF;"
                  >
                    {{ $t('BUTTON_PIMENT_BUY') }}
                  </div>
                </div>
              </button>
            </form>
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↑「クレジットカードで購入」ボタン ↑ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ==+=========+=========+=========+=========+=========+====== -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↓「NFTカードはこちら」ボタン、又は、↓ -->
            <!-- ↓「日本円で購入」ボタン             ↓ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <form @submit.prevent="onSubmitStores"
                  class="flex justify-center"
            >
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓「NFTカードはこちら」ボタン ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!--
                「NFTカードはこちら」ボタンの四角
                  SmartPhone
                    LAYOUT
                      width: 327px; PCは width: 211px;
                      height: 39px; PCは height: 38.93px; 他はスマホと同じ
                      top: 433px;  18px(433-(415+0))  mt-4 margin-top: 1rem; /* 16px */スマホ
                      top: 213px;  14px(213-(199+0))  mt-3.5 margin-top: 0.875rem; /* 14px */PC採用↑
                      border-radius: 8px 0px 0px 0px;
                      border: 2px 0px 0px 0px;
                    BORDER
                      border: 2px solid #1F2023
                「NFTカードはこちら」ボタンのテキスト
                  SmartPhone PC共通
                    LAYOUT
                      height: 14.53px;
                    TYPOGRAPHY
                      font-family: Inter;
                      font-size: 14px;
                      font-weight: 600;
                      line-height: 16.94px;
                    COLOR
                      background: #1F2023;
              -->
              <button
                v-if = "!isReady && !stopped && projectDetail.stores_btn === 1 && projectDetail.payment === 1 && sold < totalQuantity && new Date().getTime() < endTimeInMilliseconds
                      && new Date().getTime() > startTimeInMilliseconds && isWhitelisted"
                :disabled="isMaintaining"
                type="submit"
                :class="[
                  'w-80 sm:w-60 disabled:cursor-not-allowed rounded-xl disabled:bg-disabled bg-white hover:opacity-70 mt-2'
                ]"
                style="height: 39px; border: 2px solid #1F2023;
                      font-family: Inter; font-size: 14px; font-weight: 600;
                      color: #1F2023;"
              >
                <div class="mx-auto">
                  <div class="flex justify-center items-center">
                    {{ $t('BUTTON_STORES_THIS') }}
                    <img src="/images/new_tab.png" style="transform: scale(0.6)" alt="ALT" />
                  </div>
                </div>
              </button>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑「NFTカードはこちら」ボタン ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓「日本円で購入」ボタン ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <button
                v-else-if = "!isReady && !stopped && projectDetail.stores_btn === 1 && projectDetail.payment !== 1 && sold < totalQuantity && new Date().getTime() < endTimeInMilliseconds
                      && new Date().getTime() > startTimeInMilliseconds && isWhitelisted"
                :disabled="isMaintaining"
                type="submit"
                :class="[
                  'w-80 sm:w-60 disabled:cursor-not-allowed rounded-xl disabled:bg-disabled bg-white hover:opacity-70 mt-2'
                ]"
                style="height: 39px; border: 2px solid #1F2023;
                      font-family: Inter; font-size: 14px; font-weight: 600;
                      color: #1F2023;"
              >
                <div class="mx-auto">
                  <div class="flex justify-center items-center">
                    {{ $t('BUTTON_BUY_YEN') }}
                    <img src="/images/new_tab.png" style="transform: scale(0.6)" alt="ALT" />
                  </div>
                </div>
              </button> 
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑「日本円で購入」ボタン ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
            </form>
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↑「NFTカードはこちら」ボタン、又は、↑ -->
            <!-- ↑「日本円で購入」ボタン             ↑ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <form  class="flex justify-center">
              <button
                v-if = "!isReady && !stopped && projectDetail.zaiftx_form === 1 && sold < totalQuantity && new Date().getTime() < endTimeInMilliseconds
                        && new Date().getTime() > startTimeInMilliseconds && isWhitelisted"
                  :disabled="isMaintaining"
                  @click="openZaifTxModal"
                  class="w-80 sm:w-60 BUTTON_ZAIFTX disabled:cursor-not-allowed
                        rounded-xl font-medium
                        xl:text-sm lg:text-lg md:text-sm sm:text-sm text-xs
                        xl:px-4 lg:px-3 md:px-2 sm:px-2 px-5 h-10
                        disabled:bg-disabled hover:opacity-70 mt-2"
                  style="font-family : Inter; font-weight: 600; color: #FFFFFF;"
              >
                {{ $t('BUTTON_ZAIFTX_BUY') }}
              </button>
              <shippingZaifTxModalForm
                v-if="showZaifTxModal"
                :quantity="quantity"
                @close="showZaifTxModal = false"
              />
            </form>
            <form>
              <exchangeWalletModalForm
                v-if="showExchangeWalletModal"
                :quantity="quantity"
                :modal_type="modal_type"
                :chainId="chainId"
                @close="showExchangeWalletModal = false"
                @submitBuy="onSubmitBuy"
              />
            </form>
          </ValidationObserver>
        </div>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↑「数量」入力テキストボックス、                             ↑ -->
        <!-- ↑「暗号資産で購入」ボタン、「クレジットカードで購入」ボタン ↑ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
      </div>
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ↑ 画面幅 640px 以上の場合、右列（6:6の6）。 ↑  -->
      <!-- ↑ 画面幅 640px 未満の場合、画面横一杯。     ↑  -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
    </div>
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- ↑ 画面幅 640px 以上の場合、二列(12列にして6列と6列)。↑ -->
    <!-- ↑ 画面幅 640px 未満の場合、一列。                    ↑ -->
    <!-- ↑「プリセール」「パブリックセール」購入フォーム      ↑ -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
  </div>
</template>
<!-- ====+=========+=========+=========+=========+=========+=========+====== -->
<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import { CHAIN_INFO, TOKEN_SYMBOLS } from '@/constants/chains.constant';
import { SUPPORTED_CHAIN_IDS } from '@/env.js';
import InputNumberCustomColor from '@/components/InputNumberCustomColor.vue';
import BigNumber from 'bignumber.js';
import UtilNotify from '@/utils/notify.util';
import { buyNFT, getOwnedAmount } from '@/services/blockchain/project';
// import CalendarIcon from '@/components/icons/calendar.icon.vue';
// import CryptoIcon from '@/components/common/CryptoIcon.vue';
import TargetBar from '../../common/TargetBar.vue';
import shippingModalForm from '../../modals/shippingModalForm.vue';
// ------+---------+---------+---------+---------+---------+---------+---------E
import YEN from '@/components/icons/yen.icon.vue';
import ETH from '@/components/icons/eth.icon.vue';
import Ethereum from '@/components/icons/eth.icon.vue';
import MATIC from '@/components/icons/matic.icon.vue';
import Polygon from '@/components/icons/matic.icon.vue';
import BNB from '@/components/icons/bnb.icon.vue';
import BNBSmartChain from '@/components/icons/bnb.icon.vue';
import ASTR from '@/components/icons/astr.icon.vue';
import AstarNetwork from '@/components/icons/astr.icon.vue';
import AVAX from '@/components/icons/avax.icon.vue';
import Avalanche from '@/components/icons/avax.icon.vue';
import OAS from '@/components/icons/oas.icon.vue';
import TCG from '@/components/icons/tcgt.icon.vue';
import HOMEDSE from '@/components/icons/homeverse.icon.vue';
import TCGVerse from '@/components/icons/tcgt.icon.vue';
import HOMEVerse from '@/components/icons/homeverse.icon.vue';
import MCH from '@/components/icons/mch.icon.vue';
import MCHVerse from '@/components/icons/mch.icon.vue';
import CICC from '@/components/icons/cicc.icon.vue';
// ------+---------+---------+---------+---------+---------+---------+---------E
const SymbolMap = {
  YEN,
  OAS,
  ETH,
  Ethereum,
  MATIC,
  Polygon,
  BNB,
  BNBSmartChain,
  ASTR,
  AstarNetwork,
  SBY: ASTR,
  AVAX,
  TCG,
  HOMEDSE,
  MCH,
  TCGC: TCG,
  Avalanche,
  TCGVerse,
  HOMEVerse,
  MCHVerse,
  MCHC: MCH,
  CICC,
}; // End of const SymbolMap = {...}
import shippingZaifTxModalForm from '../../modals/shippingZaifTxModalForm.vue';
import exchangeWalletModalForm from '../../modals/exchangeWalletModalForm.vue';
// ------+---------+---------+---------+---------+---------+---------+---------E
export default {
  name: 'BuyNFTSection',
  // ----+---------+---------+---------+---------+---------+---------+---------E
  components: {
    InputNumberCustomColor,
    // CalendarIcon,
    // CryptoIcon,
    TargetBar,
    shippingModalForm,
    shippingZaifTxModalForm,
    exchangeWalletModalForm,
  },
  props: {
    type: {
      validator(value) {
        return ['PRESALE', 'PUBLIC_SALE'].includes(value);
      },
    }, // End of type: {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    endTimeInMilliseconds: {
      type: Number,
      default: 0,
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    startTimeInMilliseconds: {
      type: Number,
      default: 0,
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    chainId: {
      type: Number,
      validator(value) {
        return SUPPORTED_CHAIN_IDS.includes(value);
      },
    }, // End of chainId: {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    price: {
      type: [String, Number],
      default: '0',
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    totalQuantity: {
      type: Number,
      default: 0,
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    sold: {
      type: Number,
      default: 0,
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    endTime: {
      type: String,
      required: false,
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    startTime: {
      type: String,
      required: false,
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    maxSalePerUser: {
      type: Number,
      required: true,
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    contractId: {
      type: Number,
      default: null,
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    isWhitelisted: {
      type: Boolean,
      default: true,
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    calendarUrl: {
      type: String,
      required: true,
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    stopped: {
      type: Boolean,
      default: false,
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    isReady: {
      type: Boolean,
      default: false,
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    isTBA: {
      type: Boolean,
      default: false,
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    isPriceTBA: {
      type: Boolean,
      default: false,
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    target: {
      type: Number,
      default: 0,
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    isCombinedTarget: {
      type: Boolean,
      default: false,
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    presaleSold: {
      type: Number,
      default: 0,
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    publicSaleSold: {
      type: Number,
      default: 0,
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    transferUnsoldDisabled: {
      type: Boolean,
      default: false,
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    tokenAddress: {
      type: String,
      default: null,
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    allowance: {
      type: [String, Number],
      default: () => '0',
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    hasAffiliate: {
      type: Boolean,
      default: () => false,
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    isMaintaining: {
      type: Boolean,
      default: () => false,
    },
  }, // End of props: {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  data() {
    return {
      types: {
        PRESALE: {
          title: 'PRESALE_TEXT',
        },
        PUBLIC_SALE: {
          title: 'PUBLIC_SALE_TEXT',
        },
      }, // End of type: {...}
      quantity: '1',
      CHAIN_INFO,
      endTimeMilli:
        new Date().getTime() < this.startTimeInMilliseconds
          ? this.startTimeInMilliseconds
          : this.endTimeInMilliseconds,
      ownedAmount: 0,
      loadingAllowance: false,
      timeOuts: [],
      modal_type: 0,
      showModal: false,
      showExchangeWalletModal: false,
      showZaifTxModal:false,
      SymbolMap,
    }; // End of return {...}
  }, // End of data() {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  computed: {
    ...mapGetters({
      connectedChainInfo: 'wallet/connectedChainInfo',
      account: 'wallet/account',
    }),
    // --+---------+---------+---------+---------+---------+---------+---------E
    ...mapState({
      provider: (state) => state.wallet?.provider || null,
      projectDetailMap: (state) => state.projects.projectDetailMap,
      locale: (state) => state?.setting.locale,
    }), // End of ...mapState({...})
    // --+---------+---------+---------+---------+---------+---------+---------E
    projectDetail() {
      const { id } = this.$route.params;
      console.debug({ id, projectDetailMap: this.projectDetailMap });
      if (id) {
        const data = this.projectDetailMap[id];
        console.debug({ data });
        return data;
      } // End of if (id) {...}
      return {};
    }, // End of projectDetail() {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    isMatchChainId() {
      return this.provider && this.provider.chainId === this.chainId;
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    totalPrice() {
      if (this.isPriceTBA) {
        return 'TBA';
      }
      return new BigNumber(this.quantity).multipliedBy(this.price).toFixed() || '0';
    }, // End of totalPrice() {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    symbol() {
      return this.tokenAddress
        ? TOKEN_SYMBOLS[this.chainId][this.tokenAddress]
        : CHAIN_INFO[this.chainId].nativeCurrency.symbol;
    }, // End of symbol() {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    maxPurchasableAmount() {
      return Math.min(this.totalQuantity - this.sold, this.maxSalePerUser - this.ownedAmount);
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    ratio() {
      return (this.sold / this.totalQuantity) * 100;
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    // 暗号資産アイコンを表示するための関数。
    iconSvg() {
      return this.SymbolMap[this.symbol];
    },
  }, // End of computed: {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  watch: {
    async isMatchChainId() {
      this.ownedAmount = await this.getOwnedQuantity();
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    async account() {
      this.ownedAmount = await this.getOwnedQuantity();
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    quantity() {
      this.timeOuts.forEach((item) => clearTimeout(item));
      this.timeOuts = [];
      this.loadingAllowance = true;
      this.timeOuts.push(
        setTimeout(() => {
          this.loadingAllowance = false;
        }, 500)
      ); // End of this.timeOuts.push(...)
    }, // End of quantity() {...}
  }, // End of watch: {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  async mounted() {
    this.ownedAmount = await this.getOwnedQuantity();
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  beforeDestroy() {
    // イベントリスナーを解除
    this.$root.$off('chain-switched', this.boundShowModalAfterSwitch);
    console.debug('Event listener unregistered');
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  methods: {
    ...mapActions({
      fetchProjectDetail: 'projects/fetchProjectDetail',
    }),
    // --+---------+---------+---------+---------+---------+---------+---------E
    ...mapMutations({
      setModalRequestState: 'wallet/UPDATE_SHOW_MODAL_CONNECT',
    }),
    // --+---------+---------+---------+---------+---------+---------+---------E
    async getOwnedQuantity() {
      // alert(`this.chainId=${this.chainId}: getOnwnedQuantity: methods: BuySection.vue`);
      if (this.isReady || !this.isMatchChainId || !this.$connector.provider || !this.account) {
        return 0;
      }
      const quantity = await getOwnedAmount({
        provider: this.$connector.provider,
        buyer: this.account,
        chainId: this.chainId,
        isPresale: this.type === 'PRESALE',
        projectId: this.contractId,
      }); // End of const quantity = await getOwnedAmount({...})
      return quantity;
    }, // End of async getOwnedQuantity() {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    async onFinish() {
      console.log('on finish');
      await this.fetchProjectDetail(this.$route.params.id);
      this.ownedAmount = await this.getOwnedQuantity();
      setTimeout(() => {
        this.getTime();
      }, 1000);
    }, // End of async onFinish() {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    getTime() {
      this.endTimeMilli =
        new Date().getTime() < this.startTimeInMilliseconds
          ? this.startTimeInMilliseconds
          : this.endTimeInMilliseconds;
      if (new Date().getTime() < this.endTimeMilli) {
        this.$refs.countdownRef.startCountdown(true);
      }
    },
    openZaifTxModal() {
      this.showZaifTxModal = true;
    },
    handleMetamask(type) {
      // typeを固定し、showModalAfterSwitchに渡す関数
      const boundShowModalAfterSwitch = (success) => {
        this.showModalAfterSwitch(success, type);
      };
      // 'chain-switched' イベントでネットワーク変更後にモーダルを表示するハンドラを設定
      this.$root.$on('chain-switched', boundShowModalAfterSwitch);
      this.$root.$emit('switch-metamask-chain', this.chainId);
    },
    async showModalAfterSwitch(success,type) {
      const isValid = await this.$refs.buyForm.validate();
      // +---------+---------+---------+---------+---------+---------+---------E
      if (!isValid) {
        return;
      }
      // +---------+---------+---------+---------+---------+---------+---------E
      if(this.tokenAddress && this.isLess(this.allowance, this.totalPrice || '0')) {
        return;
      }
      // +---------+---------+---------+---------+---------+---------+---------E
      if (success) {
        // 引き換えウォレットモーダルが有効の場合
        if(this.projectDetail.exchange_wallet_info === 1){
          // 暗号資産で購入する場合
          if (type== '1'){
            this.modal_type = 1;
          // クレジットカード(piement)で購入する場合
          } else if (type== '2') {
            this.modal_type = 2;
          }
          this.showExchangeWalletModal = true;
        }
        // 郵送情報モーダルが有効の場合
        else if(this.projectDetail.shipping_form === 1){
          this.showModal = true;
        }
      } else {
        console.error('チェーンの切り替えに失敗しました。');
      }
      // 'chain-switched' イベントからモーダル表示のハンドラを解除
      this.$root.$off('chain-switched', this.boundShowModalAfterSwitch);
    },
    async onSubmitBuy() {
      try {
        const isValid = await this.$refs.buyForm.validate();
        if (!isValid) {
          return;
        }
        this.$emit('buying', true);
        const provider = this.$connector.provider;
        const buyer = this.account;
        const isPresale = this.type === 'PRESALE';
        const amount = Number(this.quantity);
        const price = this.price;
        await buyNFT({
          provider,
          buyer,
          chainId: this.chainId,
          isPresale,
          amount,
          projectId: this.contractId,
          price,
          backendId: this.$route.params.id,
          isNativeToken: !this.tokenAddress,
        }); // End of await buyNFT({...})
        setTimeout(async () => {
          await this.fetchProjectDetail(this.$route.params.id);
          this.ownedAmount = await this.getOwnedQuantity();

          UtilNotify.success(this.$notify, { message: 'NOTIFICATION_SUCCESS_TRANSACTION' });
          this.$emit('buying', false);
        }, 20000); // End of setTimeout(async () => {...}...)
      } catch (err) {
        await this.fetchProjectDetail(this.$route.params.id);
        if (this.stopped) {
          UtilNotify.error(this.$notify, { message: 'NOTIFICATION_SALE_STOPPED' }, this.$modal);
        } else {
          UtilNotify.error(this.$notify, err, this.$modal);
        }
        this.ownedAmount = await this.getOwnedQuantity();

        this.$emit('buying', false);
      } // End of try {...} catch (err) {...}
    }, // End of async onSubmitBuy() {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    async onSubmitStores() {
      try {
        let nextUrl = this.projectDetail.stores_url;
        if(!nextUrl){
          throw 'error';
        }
        //指定のURLに遷移
        // window.location.href = nextUrl;
        window.open(nextUrl, '_blank');
      } catch(err) {
        console.debug(err);
        UtilNotify.error(this.$notify, { message: 'SYSTEM_ERROR' }, this.$modal);
      } // try {...} catch(err) {...}
    }, // End of async onSubmitStores() {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    async onSubmitPiement() {
        const isValid = await this.$refs.buyForm.validate();

        //フォームの形式が適切でない場合
        if (!isValid) {
          return;
        }
        try {
          //遷移url初期値
          let piementUrl = '';

          // projectidが94(ONIちゃん)の場合 ※本番環境
          if (this.$route.params.id === '94'){ 
            piementUrl =  `https://paypiement.xyz/projects/f6bcb270-321c-4859-9b24-4b9c2b6f37f9?quantity=${this.quantity}`;
          }
          // projectidが97(Credit Card test 2024.02.01)の場合 ※本番環境テスト用
          else if (this.$route.params.id === '97'){ 
            piementUrl =  `https://paypiement.xyz/projects/7e32d8f5-5aba-413c-9445-7ee89459cd58?quantity=${this.quantity}`;
          } 
          // projectidが98(Bring The Noise)の場合 ※本番環境
          else if (this.$route.params.id === '98'){ 
            piementUrl =  `https://paypiement.xyz/projects/0c171d8f-7506-44f6-8f50-b390c82d7d53?quantity=${this.quantity}`;
          } 
          // projectidが99(二人のバニーガールズ)の場合 ※本番環境
          else if (this.$route.params.id === '99'){ 
            piementUrl =  `https://paypiement.xyz/projects/2a2bb4aa-d90b-4e42-9a2b-4c0a965aa64f?quantity=${this.quantity}`;
          } 
          // projectidが100(狐巫女娘 神楽アスカ)の場合 ※本番環境
          else if (this.$route.params.id === '100'){ 
            piementUrl =  `https://paypiement.xyz/projects/82477cdd-93b6-4e68-b2f6-7ca28ca722fd?quantity=${this.quantity}`;
          } 
          // projectidが101(うみそら)の場合 ※本番環境
          else if (this.$route.params.id === '101'){ 
            piementUrl =  `https://paypiement.xyz/projects/37338688-d2d3-43ad-a14d-ae01d85e1e3e?quantity=${this.quantity}`;
          }
          // projectidが102(Bring The Noise2)の場合 ※本番環境
          else if (this.$route.params.id === '102'){ 
            piementUrl =  `https://paypiement.xyz/projects/0c171d8f-7506-44f6-8f50-b390c82d7d53?quantity=${this.quantity}`;
          }
          // projectidが103(おらひらおワールド)の場合 ※本番環境
          else if (this.$route.params.id === '103'){ 
            piementUrl =  `https://paypiement.xyz/projects/a7f69bd7-87db-46e9-b7b4-3b1999e343c2?quantity=${this.quantity}`;
          }
          // projectidが105(RikoPinkLips)の場合 ※本番環境
          else if (this.$route.params.id === '105'){ 
            piementUrl =  `https://paypiement.xyz/projects/1de18f0a-e74b-4a59-88cc-de47d2027028?quantity=${this.quantity}`;
          } 
          // projectidが106(DAVID RABbIT)の場合 ※本番環境
          else if (this.$route.params.id === '106'){ 
            piementUrl =  `https://paypiement.xyz/projects/71df0f2d-e29c-45f5-bc12-04226d507463?quantity=${this.quantity}`;
          }
          // projectidが107(My wish)の場合 ※本番環境
          else if (this.$route.params.id === '107'){ 
            piementUrl =  `https://paypiement.xyz/projects/95b1597e-241e-4a69-83f8-be5a39842305?quantity=${this.quantity}`;
          }
          // projectidが108(RGBガールズ！)の場合 ※本番環境
          else if (this.$route.params.id === '108'){ 
            piementUrl =  `https://paypiement.xyz/projects/5194e97b-0f6b-4453-a74c-745b0ae8f154?quantity=${this.quantity}`;
          }
          // projectidが109(おしゃまちゃん)の場合 ※本番環境
          else if (this.$route.params.id === '109'){ 
            piementUrl =  `https://paypiement.xyz/projects/c3b964d9-4c28-4fb6-9d6d-0291f682a610?quantity=${this.quantity}`;
          }
          // projectidが111(Deep Sea Scratch Art)の場合 ※本番環境
          else if (this.$route.params.id === '111'){ 
            piementUrl =  `https://paypiement.xyz/projects/244415c4-e5b9-442a-9de1-d0a592c4a960?quantity=${this.quantity}`;
          }
          // projectidが130(Onikochima - 璞pop)の場合 ※本番環境
          else if (this.$route.params.id === '130'){ 
            piementUrl =  `https://paypiement.xyz/projects/3f3b1805-1bc9-4cbf-938c-fe80d778763c?quantity=${this.quantity}`;
          }
          // projectidが113(FuturePunks)の場合 ※本番環境
          else if (this.$route.params.id === '113'){ 
            piementUrl =  `https://paypiement.xyz/projects/75abf1c3-23ff-4c98-9a45-edfdc93b4de9?quantity=${this.quantity}`;
          }
          // projectidが122(移ろいゆく花)の場合 ※本番環境
          else if (this.$route.params.id === '122'){ 
            piementUrl =  `https://paypiement.xyz/projects/d2344da8-aa2e-46ad-9376-79690873d065?quantity=${this.quantity}`;
          }
          // projectidが126(NINJA National Flag)の場合 ※本番環境
          else if (this.$route.params.id === '126'){ 
            piementUrl =  `https://paypiement.xyz/projects/6f50a294-1a1d-4dc2-a50b-4129d2f66f8c?quantity=${this.quantity}`;
          }
          // projectidが123(YorikoMatsudaira)の場合 ※本番環境
          else if (this.$route.params.id === '123'){ 
            piementUrl =  `https://paypiement.xyz/projects/3dd3e0d0-ab20-4a8e-86e1-eeea8bada908?quantity=${this.quantity}`;
          }
          // projectidが124(Kokone's NFT Collection Shiba Inu Collection)の場合 ※本番環境
          else if (this.$route.params.id === '124'){ 
            piementUrl =  `https://paypiement.xyz/projects/56d99b39-8671-4f6b-8782-4db44334fead?quantity=${this.quantity}`;
          }
          // projectidが125(Kokone's NFT Collection Jewelry Girl Collection)の場合 ※本番環境
          else if (this.$route.params.id === '125'){ 
            piementUrl =  `https://paypiement.xyz/projects/b25743c4-70eb-407c-85fb-0710fcff4121?quantity=${this.quantity}`;
          }
          // projectidが131(MatsuDao1000～YamatoNFT～)の場合 ※本番環境
          else if (this.$route.params.id === '140'){ 
            piementUrl =  `https://paypiement.xyz/projects/56d5af81-2766-47f8-8843-d25f5613f83b?quantity=${this.quantity}`;
          }
          // projectidが132(MatsuDao3000～YamatoNFT～)の場合 ※本番環境
          else if (this.$route.params.id === '132'){ 
            piementUrl =  `https://paypiement.xyz/projects/cf18fa98-2712-4d8b-860c-71e828b6b5b3?quantity=${this.quantity}`;
          }
          // projectidが133(PINK MAY)の場合 ※本番環境
          else if (this.$route.params.id === '133'){ 
            piementUrl =  `https://paypiement.xyz/projects/e516451d-659f-4ba6-9a9d-9425f8248ceb?quantity=${this.quantity}`;
          }
          // projectidが134(silent Ring)の場合 ※本番環境
          else if (this.$route.params.id === '134'){ 
            piementUrl =  `https://paypiement.xyz/projects/ffaf0c7c-0fd8-4902-b86c-feb3a855dc2e?quantity=${this.quantity}`;
          }
          // projectidが135(DOT DOGS)の場合 ※本番環境
          else if (this.$route.params.id === '135'){ 
            piementUrl =  `https://paypiement.xyz/projects/50193872-944b-425f-b5c9-60cf9d7e6402?quantity=${this.quantity}`;
          }
          // projectidが143(Chain Project)の場合 ※本番環境
          else if (this.$route.params.id === '143'){ 
            piementUrl =  `https://paypiement.xyz/projects/9c807a92-774d-4756-b182-5f8c399c593a?quantity=${this.quantity}`;
          }
          // projectidが138(NIWA EELS PARTY ver.Zaif × Nコレ)の場合 ※本番環境
          else if (this.$route.params.id === '138'){ 
            piementUrl =  `https://paypiement.xyz/projects/8266a652-7bf1-4395-ba8b-65d2d2ded4f9?quantity=${this.quantity}`;
          }
          // projectidが144(SOUPGIRLS チョコ×カフェラテ)の場合 ※本番環境
          else if (this.$route.params.id === '144'){ 
            piementUrl =  `https://paypiement.xyz/projects/9d1a170d-6c8c-4b2b-87c7-b12a4ce13126?quantity=${this.quantity}`;
          }
          // projectidが145(SOUPGIRLS ベリー×いちごミルク)の場合 ※本番環境
          else if (this.$route.params.id === '145'){ 
            piementUrl =  `https://paypiement.xyz/projects/d2672a3e-3fa3-47c4-a4b1-3c5b76655d45?quantity=${this.quantity}`;
          }
          // projectidが146(SOUPGIRLS 抹茶×抹茶ラテ)の場合 ※本番環境
          else if (this.$route.params.id === '146'){ 
            piementUrl =  `https://paypiement.xyz/projects/7f40a076-9617-46f6-b93b-17413a8d9f06?quantity=${this.quantity}`;
          }
          // projectidが157(【ダリンリヒ】Nコレ× Zaif INO 記念引換券ガチャ2)の場合 ※本番環境
          else if (this.$route.params.id === '157'){ 
            piementUrl =  `https://paypiement.xyz/projects/7b9741c2-0ac1-4edd-9080-0b5f00388a14?quantity=${this.quantity}`;
          }
          // idが156(ぴよこ meets Kawaii sweets2)の場合 ※本番環境
          else if (this.$route.params.id === '156'){ 
            piementUrl =  `https://paypiement.xyz/projects/3be7f6e9-e1cf-4797-8d72-bb9bfddcefa6?quantity=${this.quantity}`;
          }
          // idが150(オオカミのアオン)の場合 ※本番環境
          else if (this.$route.params.id === '150'){ 
            piementUrl =  `https://paypiement.xyz/projects/40a33431-6ec5-412e-b9df-b182f8cf9b04?quantity=${this.quantity}`;
          }
          // idが151(Rin_AyaCollection)の場合 ※本番環境
          else if (this.$route.params.id === '151'){ 
            piementUrl =  `https://paypiement.xyz/projects/ca3d10f8-5707-4628-a223-237a0acfa773?quantity=${this.quantity}`;
          }
          // idが155(Lucky Animal Collection)の場合 ※本番環境
          else if (this.$route.params.id === '155'){ 
            piementUrl =  `https://paypiement.xyz/projects/8403c590-98ed-4538-a520-b6060044ae61?quantity=${this.quantity}`;
          }
          // idが157(【ダリンリヒ】Nコレ× Zaif INO 記念引換券ガチャ 2)の場合 ※本番環境
          else if (this.$route.params.id === '157'){ 
            piementUrl =  `https://paypiement.xyz/projects/7b9741c2-0ac1-4edd-9080-0b5f00388a14?quantity=${this.quantity}`;
          }
          // idが158(POPET)の場合 ※本番環境
          else if (this.$route.params.id === '158'){ 
            piementUrl =  `https://paypiement.xyz/projects/2b646e86-3f2e-43c7-aa14-4f7a9e914b2f?quantity=${this.quantity}`;
          }
          // idが159(POPET in OSAKA)の場合 ※本番環境
          else if (this.$route.params.id === '159'){ 
            piementUrl =  `https://paypiement.xyz/projects/06ab24f4-eb39-40a1-a039-40d80b15a4a0?quantity=${this.quantity}`;
          }
          // idが161(POPET 研究所)の場合 ※本番環境
          else if (this.$route.params.id === '161'){ 
            piementUrl =  `https://paypiement.xyz/projects/10f9a46d-703c-4c77-9513-e0160f3d2ed4?quantity=${this.quantity}`;
          }
          // idが162(ペーパークラフト コレクション 【動物)の場合 ※本番環境
          else if (this.$route.params.id === '162'){ 
            piementUrl =  `https://paypiement.xyz/projects/72740377-85e5-468f-a7ac-15602b95be54?quantity=${this.quantity}`;
          }
          // idが163(海中の知らない世界)の場合 ※本番環境
          else if (this.$route.params.id === '163'){ 
            piementUrl =  `https://paypiement.xyz/projects/ebf5580b-1331-41ba-aedb-c2d9cd489683?quantity=${this.quantity}`;
          }
          // idが164(シロクマ モチーフ コレクション)の場合 ※本番環境
          else if (this.$route.params.id === '164'){ 
            piementUrl =  `https://paypiement.xyz/projects/2c14f653-87e8-496d-87e3-2f3fa8bbaf2a?quantity=${this.quantity}`;
          }
          // idが165(cyberpunk animal armor)の場合 ※本番環境
          else if (this.$route.params.id === '165'){ 
            piementUrl =  `https://paypiement.xyz/projects/ac9fdf25-c22e-432b-9591-210fe2ff8ddb?quantity=${this.quantity}`;
          }
          // idが166(ぼたもちくん)の場合 ※本番環境
          else if (this.$route.params.id === '166'){ 
            piementUrl =  `https://paypiement.xyz/projects/3cce3676-d5c1-41e4-b7c3-09f3e538470d?quantity=${this.quantity}`;
          }
          // idが167(Ink'z Monster_Black)の場合 ※本番環境
          else if (this.$route.params.id === '167'){ 
            piementUrl =  `https://paypiement.xyz/projects/d594c673-9ae2-4744-ba3c-eb2791883d8e?quantity=${this.quantity}`;
          }
          // idが171(Ink'z Monster_Rainbow)の場合 ※本番環境
          else if (this.$route.params.id === '171'){ 
            piementUrl =  `https://paypiement.xyz/projects/518d35f3-6e48-4f79-b32b-c654e714f620?quantity=${this.quantity}`;
          }
          // idが169(Gate of Heaven)の場合 ※本番環境
          else if (this.$route.params.id === '169'){ 
            piementUrl =  `https://paypiement.xyz/projects/5d3f9f63-c242-4ca7-a0ab-008cddd44ff3?quantity=${this.quantity}`;
          }
          // idが170(BOSS-DONUTS)の場合 ※本番環境
          else if (this.$route.params.id === '170'){ 
            piementUrl =  `https://paypiement.xyz/projects/d6a2fddc-3bd4-41bd-8810-e1737afbf3cf?quantity=${this.quantity}`;
          }
          // idが172(蜜ガール)の場合 ※本番環境
          else if (this.$route.params.id === '172'){ 
            piementUrl =  `https://paypiement.xyz/projects/b312f591-1387-4fae-b40b-08dd4f21a25a?quantity=${this.quantity}`;
          }
          // idが173(STARING GHOST CREW)の場合 ※本番環境
          else if (this.$route.params.id === '173'){ 
            piementUrl =  `https://paypiement.xyz/projects/7c2daad2-8537-474d-87fa-7194d4a9c55f?quantity=${this.quantity}`;
          }
          // idが110(ディライズ ラストメモリーズ)の場合 ※本番環境
          else if (this.$route.params.id === '110'){ 
            piementUrl =  `https://paypiement.xyz/projects/d8df64b6-9b26-469d-8401-73cff670d54e?quantity=${this.quantity}`;
          }
          // idが188(Piyoko meets summer sweets)の場合 ※本番環境
          else if (this.$route.params.id === '188'){ 
            piementUrl =  `https://paypiement.xyz/projects/a2ca1dc5-4b11-4a60-a47e-aec25448d3bb?quantity=${this.quantity}`;
          }
          // idが189(Piyoko meets sea creatures)の場合 ※本番環境
          else if (this.$route.params.id === '189'){ 
            piementUrl =  `https://paypiement.xyz/projects/182b17e4-2cf1-434b-a4dd-71d03c6c2f92?quantity=${this.quantity}`;
          }
          // idが190(web3 Underground IDOL "UCHACO")の場合 ※本番環境
          else if (this.$route.params.id === '190'){ 
            piementUrl =  `https://paypiement.xyz/projects/e518832e-18ad-417f-ac37-3d4c7da710fb?quantity=${this.quantity}`;
          }
          // idが191(orahirao world２)の場合 ※本番環境
          else if (this.$route.params.id === '191'){ 
            piementUrl =  `https://paypiement.xyz/projects/d581f9f8-39f3-4c34-a3b0-64c57b07dc7e?quantity=${this.quantity}`;
          }
          // idが179(YAIVA)の場合 ※本番環境
          else if (this.$route.params.id === '179'){ 
            piementUrl =  `https://paypiement.xyz/projects/db35230d-da04-45fd-a972-ef195395dc49?quantity=${this.quantity}`;
          }
          // idが192(Ｙｏｒｉｋｏ Ｍａｔｓｕｄａｉｒａ)の場合 ※本番環境
          else if (this.$route.params.id === '192'){ 
            piementUrl =  `https://paypiement.xyz/projects/3bd3b229-2aa8-4574-8f6b-81e5871542d4?quantity=${this.quantity}`;
          }
          // idが181(Underground)の場合 ※本番環境
          else if (this.$route.params.id === '181'){ 
            piementUrl =  `https://paypiement.xyz/projects/336e8659-6380-436c-a69a-7c599a7b58fa?quantity=${this.quantity}`;
          }
          // idが182(MatsuDao～Y.M.T.NFT～)の場合 ※本番環境
          else if (this.$route.params.id === '182'){ 
            piementUrl =  `https://paypiement.xyz/projects/505181ed-cab6-4090-918d-bdc352039919?quantity=${this.quantity}`;
          }
          // idが197(ネコさんとネコ耳マーメイド)の場合 ※本番環境
          else if (this.$route.params.id === '197'){ 
            piementUrl =  `https://paypiement.xyz/projects/f6581e2f-d19a-4b5d-a218-70c58207c9fe?quantity=${this.quantity}`;
          }
          else {
            throw new Error('This is not compatible with credit card payments.');
          }

          // ウォレット接続済みの場合、クエリパラメータを追加
          if(this.isMatchChainId){
            piementUrl = piementUrl + `&recipientAddress=${this.account}`;
          }

          //指定のURLに遷移
          window.location.href = piementUrl;
        } catch(err) {
          console.debug(err);
          UtilNotify.error(this.$notify, { message: 'SYSTEM_ERROR' }, this.$modal);
        } // End of try {...} catch(err) {...}
    }, // End of async onSubmitPiement() {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    isLess(num1, num2) {
      return BigNumber(num1).lt(num2);
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    approveToken() {
      this.$emit('onApproveToken');
    },
  }, // End of methods: {...}
}; // End of export default {...}
</script>
<!-- ====+=========+=========+=========+=========+=========+=========+====== -->
<style
  lang="scss"
  scoped
>
.buy-buttons {
  width: 100%;
}
.BUTTON_ZAIFTX{
  background-color:#ff6600;
}
::v-deep .input-number-custom {
  @apply w-full;
}
::v-deep .input-number-custom input {
  @apply pr-2 rounded-md;
  @apply bg-white text-black text-right text-lg;
  @apply h-10;
  @apply disabled:cursor-not-allowed;
}
.timer {
  font-family: 'Lato', 'Poppins', sans-serif;
  font-variant-numeric: tabular-nums;
  .time-unit-box {
    @apply w-1/5;
  }
  .colon-box {
    width: 7%;
  }
}
.price {
  color: #C5003F;
}
.countdown-span {
  max-width: 198px;
  @media screen and (min-width: 992px) {
    max-width: 236px;
  }
}
.scaler {
  transform-origin: 15%;
  transform: scale(0.9);
  transform-origin: center;
  @screen xs {
    transform: scale(0.95);
    @apply px-2;
  }
  @screen sm {
    transform: scale(1);
    @apply px-4;
  }
}
</style>
