<template>
	<div>
		<div class="target-bar relative mx-10">
			<!-- --+---------+---------+---------+---------+---------+------ -->
			<!-- ↓ 目標販売数バーに対する販売数バーの右端位置の吹き出し ↓ -->
			<!-- --+---------+---------+---------+---------+---------+------ -->
			<!--
			<div
				class="shifter z-50"
				:style="{
					transform: `translateX(calc(${targetPercentagePosition}% - 35px))`,
				}"
			>
			-->
			<div
				class="z-50"
				:style="{
					transform: `translate(calc(${percentagePosition}% - 35px), 0px)`,
				}"
			>
				<!-- isHot の行は無意味と思われる。-->
				<TargetTooltipIcon
					:isHot="isHot"
					:content="percentagePosition"
				></TargetTooltipIcon>
			</div>
			<!-- --+---------+---------+---------+---------+---------+------ -->
			<!-- ↑ 目標販売数バーに対する販売数バーの右端位置の吹き出し ↑ -->
			<!-- --+---------+---------+---------+---------+---------+------ -->
			<!-- --+---------+---------+---------+---------+---------+------ -->
			<!-- ↓ バーとその背景の横長角丸四角 ↓ -->
			<!-- --+---------+---------+---------+---------+---------+------ -->
			<div class="rounded-full">
				<!-- --------+---------+---------+---------+---------+------ -->
				<!-- ↓ バー ↓ -->
				<!-- --------+---------+---------+---------+---------+------ -->
				<a-slider
					:disabled="true"
					class="cursor-default z-50"
					:class="[
						{ 'slider-hot': isHot },
						sold > 0 ? (isPresale ? 'slider-presale' : 'slider-publicsale') : '',
					]"
					:value="sliderValue"
					:max="maxSliderValue"
				></a-slider>
				<!-- --------+---------+---------+---------+---------+------ -->
				<!-- ↑ バー ↑ -->
				<!-- --------+---------+---------+---------+---------+------ -->
				<!-- --------+---------+---------+---------+---------+------ -->
				<!-- ↓ バーの背景の横長角丸四角。           ↓ -->
				<!-- ↓ 高さ h-4 より小さくしても変わらない。↓ -->
				<!-- --------+---------+---------+---------+---------+------ -->
				<div
					class="cover absolute -bottom-9 py-10 rounded-full bg-white z-30 opacity-50 w-full h-4"
				></div>
				<!-- --------+---------+---------+---------+---------+------ -->
				<!-- ↑ バーの背景の横長角丸四角。           ↑ -->
				<!-- ↑ 高さ h-4 より小さくしても変わらない。↑ -->
				<!-- --------+---------+---------+---------+---------+------ -->
			</div>
			<!-- --+---------+---------+---------+---------+---------+------ -->
			<!-- ↑ バーとその背景の横長角丸四角 ↑ -->
			<!-- --+---------+---------+---------+---------+---------+------ -->
		</div>
		<!-- ------+---------+---------+---------+---------+---------+------ -->
		<!-- ↓ バーの下の 0% と 100% の表示 ↓ -->
		<!-- ------+---------+---------+---------+---------+---------+------ -->
		<!--<div class="text-left text-sm mt-1">{{ percentSoldWithTarget }}%</div>-->
		<div class="ml-8 mr-4 flex justify-between text-sm mt-1">
			<div>0%</div>  <div>100%</div>
		</div>
		<!-- ------+---------+---------+---------+---------+---------+------ -->
		<!-- ↑ バーの下の 0% と 100% の表示 ↑ -->
		<!-- ------+---------+---------+---------+---------+---------+------ -->
	</div>
</template>
<!-- ====+=========+=========+=========+=========+=========+=========+====== -->
<script>
import TargetTooltipIcon from '../icons/target-tooltip.icon.vue';
// ------+---------+---------+---------+---------+---------+---------+---------E
export default {
	name: 'TargetBar',
	// --+---------+---------+---------+---------+---------+---------+---------E
	components: { TargetTooltipIcon },
	// --+---------+---------+---------+---------+---------+---------+---------E
	props: {
		target: {
			type: Number,
			required: true,
		},
		// --------+---------+---------+---------+---------+---------+---------E
		sold: {
			type: Number,
			default: 0,
		},
		// --------+---------+---------+---------+---------+---------+---------E
		// 使っていない。
		hotAtEnd: {
			type: Boolean,
			default: false,
		},
		// --------+---------+---------+---------+---------+---------+---------E
		isPresale: {
			type: Boolean,
			default: true,
		},
	}, // End of props: {...}
	// --+---------+---------+---------+---------+---------+---------+---------E
	computed: {
		// 目標販売数に対する販売数の割合。
		percentSoldWithTarget() {
			const value = Math.round(((Number(this.sold) * 100) / Number(this.target)) * 100) / 100;

			return value;
		}, // End of percentSoldWithTarget() {...}
		// --------+---------+---------+---------+---------+---------+---------E
		// 販売数が目標販売数を超えたら真、でなければ偽。
		isHot() {
			// alert(`this.sold=${this.sold}, this.target=${this.target}: computed: isHot: TargetBar.vue`);
			return this.sold > this.target;
		},
		// --------+---------+---------+---------+---------+---------+---------E
		targetPercentagePosition() {
			if (this.isHot) {
				return (
					Math.round(((Number(this.sliderValue) * 100) / Number(this.maxSliderValue)) * 100) / 100
				);
			} // End of if (this.isHot) {...}
			return 100;
		}, // End of targetPercentagePosition() {...}
		// --------+---------+---------+---------+---------+---------+---------E
		// 販売数が目標販売数を超えていたら、100(%)、
		// でなければ、販売数／目標販売数(%)。
		percentagePosition() {
			if (!this.isHot) {
				return (
					Math.round(((Number(this.sliderValue) * 100) / Number(this.maxSliderValue)) * 100) / 100
				);
			} // End of if (this.isHot) {...}
			return 100;
		}, // End of percentagePosition() {...}
		// --------+---------+---------+---------+---------+---------+---------E
		// 販売数が目標販売数を超えていたら販売数、でなければ、目標販売数。
		maxSliderValue() {
			if (this.isHot) {
				return this.sold;
			}
			return this.target;
		}, // End of maxSliderValue() {...}
		// --------+---------+---------+---------+---------+---------+---------E
		// 販売数が目標販売数を超えていたら目標販売数、でなければ、販売数。
		sliderValue() {
			if (this.isHot) {
				return this.target;
			}
			return this.sold;
		}, // End of sliderValue() {...}
		// --------+---------+---------+---------+---------+---------+---------E
		// 使っていない。
		showHotAtEnd() {
			return this.hotAtEnd && this.targetPercentage < 70;
		},
	}, // End of computed: {...}
}; // End of export default {...}
</script>
<!-- ====+=========+=========+=========+=========+=========+=========+====== -->
<style
	lang="scss"
	scoped
>
.target-bar {
	/* height: 65px; バーの高さ。修正前 */
	height: 150px; /* バーの高さ。大きくすると上の空白が高くなる。 */
	display: flex;
	flex-direction: column;
	justify-content: flex-end; /* 下寄せ */
	::v-deep .ant-slider {
		@apply m-0 p-0;
		.ant-slider-handle {
			cursor: default;
			display: none;
		}
		&-rail {
			height: 3px; /* バーの太さ */
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%); /* バーの縦位置 */
			border-radius: 8px; /* 意味不明 */
		}

		.ant-slider-track {
			height: 14px !important; /* メモリ縦線の高さ */
		}
	}
	::v-deep .slider-hot.ant-slider {
		.ant-slider-rail {
			height: 14px !important;
		}
		.ant-slider-track {
			display: none;
		}
		.ant-slider-handle {
			display: inline-block;
			border: none;
			border-radius: 0px;
			width: 3px;
			position: relative;
			top: -2px;
		}
	}
	::v-deep .slider-presale.ant-slider {
		.ant-slider-rail {
			background-color: #2f5fe7 !important;
		}
		.ant-slider-track {
			background-color: #2f5fe7 !important;
		}
	}
	::v-deep .slider-publicsale.ant-slider {
		.ant-slider-rail {
			background-color: #ec1a8f !important; /* バーの色 */
		}
		.ant-slider-track {
			background-color: #ec1a8f !important; /* メモリのような縦線の色 */
		}
	}
	.cover {
		width: 110%;
		left: -5%;
	}
}
</style>
