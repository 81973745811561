export function getScanUrl(chainId, contractAddress) {
    switch (chainId) {
      case 1: // Ethereum Mainnet
        return `https://etherscan.io/address/${contractAddress}`;
      case 56: // Binance Smart Chain Mainnet
        return `https://bscscan.com/address/${contractAddress}`;
      case 137: // Polygon Mainnet
        return `https://polygonscan.com/address/${contractAddress}`;
      case 43114: // Avalanche Mainnet
        return `https://subnets.avax.network/c-chain/address/${contractAddress}`;
      case 592: // Astar Mainnet
        return `https://astar.blockscout.com/address/${contractAddress}`;
      case 2400: // TCG Verse
        return `https://explorer.tcgverse.xyz/address/${contractAddress}`;
      case 29548: // MCH Verse
        return `https://explorer.oasys.mycryptoheroes.net/address/${contractAddress}`;
      case 19011: // HOME Verse
        return `https://explorer.oasys.homeverse.games/address/${contractAddress}`;
      default:
        return `https://etherscan.io/address/${contractAddress}`; // デフォルトはEthereum
    }
  }