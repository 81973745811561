<template>
  <div class="target-tooltip-wrapper flex">
    <div class="target-tooltip-icon relative top-2">
      <svg
        width="70"
        height="42"
        viewBox="0 0 70 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 28.8571V4C1 2.34315 2.34314 1 4 1H66C67.6569 1 69 2.34315 69 4V28.8571C69 30.514 67.6569 31.8571 66 31.8571H45.53C44.7257 31.8571 43.9551 32.1801 43.3911 32.7536L35.281 41L27.1709 32.7536C26.6069 32.1801 25.8363 31.8571 25.0319 31.8571H4C2.34315 31.8571 1 30.514 1 28.8571Z"
          fill="white"
          fill-opacity="0.2"
          stroke="black"
        />
      </svg>
      <div class="font-medium content-text" style="font-size: 0.6rem; white-space: nowrap;">
        達成率 {{ content }}%
      </div>
    </div>
    <HotIcon
      :class="['relative -top-1', isHot ? 'visible' : 'invisible']"
    ></HotIcon>
  </div>
</template>

<script>
import HotIcon from "@/components/icons/hot.icon.vue";
export default {
  name: "TargetTooltipIcon",
  components: { HotIcon },
  props: {
    content: {
      type: String,
      default: "100%",
    },
    isHot: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.target-tooltip-icon {
  display: inline-block;
  position: relative;
  .content-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -80%);
  }
}
</style>
