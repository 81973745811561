<template>
  <div class="modal">
    <div class="modal-content">
      <span @click="close" class="close">&times;</span>
      <div class="modal-header">
        <div class="project-name font-bold">
          {{ projectDetail.name_ja }}<br>({{ projectDetail.name }})
        </div>
        <div class="quantity-display font-semibold">
          購入数量(QUANTITY) : {{ quantity }}
        </div>
      </div>
      <div class="modal-body">
        <div class="grid grid-cols-1 gap-4">
          <a href="https://api.zaif-ino.com/static/DeepLinkWalletCreate.pdf" target="_blank" class="link-button">Deeplinkウォレットの作成方法はこちら <br>(Click here to learn how to create a Deeplink wallet.)</a>
          <div class="input-group">
            <label for="email">メールアドレス (Mail Address)</label>
            <input id="email" type="email" placeholder="メールアドレス (Mail Address)" class="input-field" v-model="user.email" maxlength="255" required>
          </div>
          <div class="input-group">
            <div class="wallet-label-and-button">
              <label for="polygonAddress" class="wallet-label">Polygonウォレットアドレス (Polygon Wallet Address)</label>
              <div v-if="!account">
                <span class="mismatch-message">※ウォレットコネクトしている場合自動反映されます。</span>
              </div>
              <div v-else-if="!isMatchChainId">
                <span class="mismatch-message">※ウォレットコネクトしている場合自動反映されます。</span>
              </div>
            </div>
            <input 
              id="polygonAddress" 
              type="text" 
              placeholder="Polygonウォレットアドレス (Polygon Wallet Address)" 
              class="input-field" 
              v-model="user.wallet" 
              :disabled="modal_type === 1"
              maxlength="255" 
              required>
          </div>
          <div class="input-group">
            <label for="exchange_wallet">Deeplinkウォレットアドレス (DeepLink Wallet Address)</label>
            <input id="exchange_wallet" type="text" placeholder="Deeplinkウォレットアドレス (DeepLink Wallet Address)" class="input-field" v-model="user.exchange_wallet" maxlength="255" required>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button @click="onAddressSubmitForm($event)" class="submit-button">
          フォームを送信し購入<br>SUBMIT FORM AND PURCHASE
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { addExchangeWalletInfo } from '@/services/exchange_wallet_info';
import UtilNotify from '@/utils/notify.util';

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    quantity: {
      type: String,
      required: true,
    },
    modal_type: {
      type: Number,
      required: true,
    },
    chainId: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      user: {
        email: '',
        exchange_wallet: '',
        wallet: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      account: 'wallet/account',
    }),
    ...mapState({
      provider: (state) => state.wallet?.provider || null,
      projectDetailMap: (state) => state.projects.projectDetailMap,
      locale: (state) => state?.setting.locale,
    }),
    projectDetail() {
      const { id } = this.$route.params;
      if (id) {
        return this.projectDetailMap[id] || {};
      }
      return {};
    },
    isMatchChainId() {
      return this.provider && this.provider.chainId === this.chainId;
    },
  },
  watch: {
    // account プロパティが変更されたときに実行
    account(newVal) {
      // チェーンが一致している場合
      if (this.isMatchChainId){
        this.user.wallet = newVal;
      // チェーンが一致していない場合
      } else {
        this.user.wallet = '';
      }
    }
  },
  mounted() {
    // モーダルが開いたときに、ウォレットが取得でき、チェーンが一致している場合
    if (this.account && this.isMatchChainId) {
        this.user.wallet = this.account;
      }
    },
  methods: {
    close() {
      this.$emit('close');
    },
    validateInput() {
      if (this.user.email.length > 255 || !this.user.email) {
        //256文字以上は入力できないため、通常未入力の場合のみこのルートを通る
        alert('メールアドレスが未入力です。');
        return false;
      }
      if (this.user.wallet.length > 255 || !this.user.wallet) {
        //256文字以上は入力できないため、通常未入力の場合のみこのルートを通る
        alert('Polygonウォレットが未入力です。');
        return false;
      }
      if (this.user.exchange_wallet.length > 255 || !this.user.exchange_wallet) {
        //256文字以上は入力できないため、通常未入力の場合のみこのルートを通る
        alert('Deeplinkウォレットが未入力です。');
        return false;
      }
      return true;
    },
    validateEmail() {
      //以下条件で検証
      //1. @ の前には、空白文字や @ 自体を含まない1文字以上の文字列が含まれる
      //2. @ の直後にも、同様に空白文字や @ を含まない1文字以上の文字列が含まれる
      //3. 最後に、ピリオド . が続き、その後に空白文字や @ を含まない1文字以上の文字列が含まれる
      const regex = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;
      return regex.test(this.user.email);
    },
    async onAddressSubmitForm(event) {
      // デフォルトでフォームの送信を防ぐ
        event.preventDefault();

      if (!this.validateInput()) {
        return;
      }
      if (!this.validateEmail()){
        alert('メールアドレスが不正な形式です。');
        return;
      }

      try {
        const payload = new FormData();
        payload.append('backend_id', this.$route.params.id);
        payload.append('project_name', this.projectDetail.name);
        payload.append('amount', this.quantity);
        payload.append('mail', this.user.email);
        payload.append('wallet', this.user.wallet);
        payload.append('exchange_wallet', this.user.exchange_wallet);
        
        // データをDBに登録する
        await addExchangeWalletInfo(payload);

        // 暗号資産で購入の場合
        if(this.modal_type == 1){
          this.$emit('submitBuy');
        }
        // クレジットカードで購入の場合
        else if(this.modal_type == 2){
          await this.onSubmitPiement();
        }
        // モーダルを閉じる
        this.$emit('close');

      } catch (err) {
        console.error(err);
        this.$emit('close');
        event.preventDefault();
      }
    },
    async onSubmitPiement() {
      try {
        //遷移url初期値
        let piementUrl = this.projectDetail.creditcard_url;
        if(!piementUrl){
          throw new Error('This is not compatible with credit card payments.');
        }
        if(!this.quantity){
          throw new Error('quantity is not obtained');
        }
        // モーダルに登録されたウォレットをクエリパラメータに追加
        piementUrl = piementUrl + `?quantity=${this.quantity}`;
        
        // モーダルに登録されたウォレットをクエリパラメータに追加
        piementUrl = piementUrl + `&recipientAddress=${this.user.wallet}`;

        //指定のURLを新規タブで開く
        window.open(piementUrl, '_blank');
      } catch(err) {
        console.debug(err);
        UtilNotify.error(this.$notify, { message: 'SYSTEM_ERROR' }, this.$modal);
      }
    },
  },
};
</script>

<style scoped>
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1050; 
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  font-family: 'Meiryo', sans-serif;
  overflow-y: auto; /* Y軸にスクロールバーを設定 */
  transition: transform 0.3s ease-in-out;
}

.modal-content {
  display: flex;
  flex-direction: column;
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 100%; 
  max-width: 600px; 
  max-height: 100vh; 
  border-radius: 8px;
}

.close {
  color: #aaa;
  align-self: flex-end;
  font-size: 24px; 
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  margin-bottom: 0.8rem;
  background-color: #C24ADC;
  width: 100%;
  padding: 12px;
  border-radius: 8px 8px 0 0;
  font-family: 'Meiryo', sans-serif;
}

.modal-body {
  flex: 1;
  overflow-y: auto;
  padding-right: 1rem;
  position: relative;
  font-family: 'Meiryo', sans-serif;
}

.modal-footer {
  display: flex;
  justify-content: center;
  padding: 1rem 0; 
  bottom: 0;
  border-top: 1px solid #ccc;
}

.input-group {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 0.7rem;
  width: 100%;
  font-family: 'Meiryo', sans-serif;
}

.input-group label {
  margin-bottom: 0.5rem;
  color: rgba(68, 37, 125, 1); 
  font-family: 'Meiryo', sans-serif;
}

.input-field {
  flex: 1;
  border: 1px solid #ccc;
  padding: 0.5rem;
  border-radius: 4px;
  color: black;
  width: 100%;
  font-family: 'Meiryo', sans-serif;
}

.postal-code-group {
  width: 100%;
}

.postal-code-container {
  display: flex;
  width: 100%;
  font-family: 'Meiryo', sans-serif;
}

.postal-code {
  flex: none;
  width: 120px;
  margin-right: 0.5rem;
  font-family: 'Meiryo', sans-serif;
}

.search-button {
  flex: none;
  padding: 0.8rem 1rem;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Meiryo', sans-serif;
}

.search-button:hover {
  background-color: #0056b3;
}

.address-group {
  width: 100%;
}

.address-input {
  width: 100%;
  font-family: 'Meiryo', sans-serif; 
}

.submit-button {
  padding: 12px 20px; 
  background-color: #C24ADC;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: block; 
  margin-top: 10px; 
  font-family: 'Meiryo', sans-serif; 
}

.submit-button:hover {
  background-color: #45a049;
}

.quantity-display {
  font-size: 1rem; 
  display: block; 
  color: white; 
  font-family: 'Meiryo', sans-serif;
  @media (max-width: 768px) {
    font-size: 0.6rem;
  }
}

.project-name {
  @media (max-width: 768px) {
    font-size: 0.6rem;
  }

  font-size: 1rem;
  margin-bottom: 10px;
  display: block;
  color: white;
  font-family: 'Meiryo', sans-serif;
}

.modal-body::after {
  content: '';
  display: block;
  width: 100%;
  height: 20px;
  background: linear-gradient(to top, #fefefe, transparent);
  position: absolute;
  bottom: 0;
  left: 0;
}

.modal-footer {
  bottom: 0;
  background: #fefefe;
  border-top: 1px solid #ccc;
}
.label-and-link {
  display: flex;
  align-items: baseline;
}
.wallet-label-and-button {
  align-items: flex-start;
}
.mismatch-message {
  color: black;
  font-weight: bold;
  display: block;
  margin-top: 10px;
}
.wallet-label {
  margin-right: 10px;
}
.link-button {
  padding: 6px 0px;
  color: #007BFF;
  text-align: left;
  text-decoration: underline;
  border-radius: 4px;
  font-weight: bold;
  font-size: 12px;
}

@media (max-width: 768px) {
    .modal-content {
      max-width: 95%;
      max-height: 85vh;
      padding: 10px;
    }

    .modal {
      padding: 10px; 
    }

    .input-field, .postal-code-container {
        width: auto; 
    }

    .submit-button {
        width: 100%; 
        margin-top: 10px; 
    }
    .search-button {
        width: 100px; 
        margin-top: 10px; 
    }

    .input-group label,
    .modal-footer{
      font-size: 10px;
    }
    @media (max-width: 400px) {
    .modal-content {
      max-width: 85%;
      max-height: 70vh;
    }
    .search-button {
        width: 60px; 
    }
    .input-field.postal-code {
        width: 150px; 
    }
  }
}
</style>
